<div class="pb-2 d-flex border-bottom align-items-center justify-content-between">
        <h5 class="modal-title mb-0" mat-dialog-title>
                {{literals.LblAssignPolicy}}
        </h5>
        <button type="button" class="close" (click)="cancel()">
            <span class="material-icons">highlight_off</span>
        </button>
    </div>
    <mat-dialog-content class="py-4">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label class="main-label">{{literals.LblSelectRoll}}</label>
                <mat-form-field class="example-chip-list d-block">
                    <mat-chip-list #chipList aria-label="Fruit selection">
                            <mat-chip
                                    *ngFor="let group of groups"
                                    [selectable]="selectable"
                                    [removable]="removable"
                                    (removed)="remove(group)">
                                {{group.roles_name}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input #groupsInput
                                    [formControl]="groupCtrl"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event)"
                                   #trigger="matAutocompleteTrigger"
                                   (click)='trigger.openPanel()'
                                   required>
                        </mat-chip-list>

                    <!-- <div *ngIf="submitted && groups.length === 0" class="invalid-feedback">
                        <div>Please select atleast one group.</div>
                    </div> -->
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let group of filteredGroups | async" [value]="group">
                            {{group.roles_name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
    
    
            <div class="modal-actions text-center">
                <button type="submit" class="btn btn-blue btn-radius">Update</button>
                <button type="button" (click)="cancel()" class="btn btn-gray btn-radius">{{literals.LblCancel}}</button>
            </div>
        </form>
    </mat-dialog-content>
