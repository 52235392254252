import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {LoginService} from '../../../login/login.service';

@Component({
  selector: 'app-add-edit-group',
  templateUrl: './view-all-functions.component.html',
  styleUrls: ['./view-all-functions.component.css']
})
export class ViewAllFunctionsComponent implements OnInit {
  data: any;

  constructor(private dialogRef: MatDialogRef<ViewAllFunctionsComponent>,
              @Inject(MAT_DIALOG_DATA) dialogData) {
    this.data = dialogData;
  }

  ngOnInit(): void {

  }

}
