import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddEditRoleComponent } from '../shared-module/components/add-edit-role/add-edit-role.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { RolesService } from './roles.service';
import { DeleteConfirmationComponent } from '../shared-module/components/delete-confirmation/delete-confirmation.component';
import { LocalStoreService } from '../shared-module/services/local-store.service';
import { DataService } from '../shared-module/services/data.service';
import { Router } from '@angular/router';
import { NotificationService } from '../toaster/notification.service';
import { LoginService } from '../login/login.service';
import { GroupService } from '../groups/group.service';
import { ViewAllGroupsComponent } from '../shared-module/components/view-all-groups/view-all-groups.component';
import { LoadingService } from '../shared-module/services/loading.service';

export interface Owner {
  id: number;
  roles_name: string;
  active: string;
  roles_group: any[];
}

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit, AfterViewInit, AfterViewChecked {
  public displayedColumns = ['checkbox', 'id', 'roles_name', 'roles_group','active', 'action'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selection = new SelectionModel<Owner>(true, []);
  roles: any;
  isListEmpty = false;
  roles_groups: any;
  finalRolesArr: any;
  data: Owner[] = [];
  fileteredRoles: any = [];
  public dataSource = new MatTableDataSource<Owner>(this.data);
  loggedUser: any;
  groups: any = [];
  literals: any;
  constructor(
    private dialog: MatDialog,
    private _roleService: RolesService,
    private _localStore: LocalStoreService,
    private _dataService: DataService,
    private _loginService: LoginService,
    private _groupService: GroupService,
    private _notifyService: NotificationService,
    public _loadingService: LoadingService, private loginService: LoginService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.literals = this.loginService.getLiterals();
    this.loggedUser = this._dataService.getLoggedUser();
    this.getAllRolesList();
    this.getAllGroupsList();
  }

  ngAfterViewInit(): void {
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
  }

  ngAfterViewChecked() {
    this.literals = this.loginService.getLiterals();
    this.cd.detectChanges();
  }

  realoadDataTableResource(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  /**
   * function used for open add group dialog
   */
  public openAddDialog() {
    this.dialog.open(AddEditRoleComponent).afterClosed().subscribe(result => {
      if (result != "" && result.created) {
        setTimeout(() => {
          this.getAllRolesList(false);
        }, 500);
      }
    });
  }

  /**
   * function used for open delete group dialog
   */
  openDeleteDialog(element) {
    this.dialog.open(DeleteConfirmationComponent).afterClosed().subscribe(result => {
      if (result.delete) {
        this.deleteGroup(element);
      }
    });
  }

  /**
   * function used for open edit group dialog
   */
  openEditModal(element: any) {
    this.dialog.open(AddEditRoleComponent, {
      data: element
    }).afterClosed().subscribe(result => {
      if (result.updated || result.cancel) {
        this.cd.detectChanges();
        setTimeout(() => {
          this.getAllRolesList(false);
        }, 500);
      }
    });
  }

  getAllGroupsList() {
    const postData = {
      request: {
        companyid: this.loggedUser.companyid,
        token: this._loginService.isLoggedInToken(),
        deviceid: this.loggedUser.deviceid
      }
    };
    this._groupService.getGroupsList(postData).subscribe((result: any) => {
      if (result.response.errormessage === 'Success' || result.response.errormessage === '') {
        this.groups = result.response.groups.ttgroups;
      } else if (result.response.errormessage === 'Invalid Session') {
        this._dataService.tokenExpired();
      }
    });
  }


  /**
   * function used to get all groups list
   */
  getAllRolesList(loaderReqired: boolean = true) {
    if (loaderReqired) {
      this._loadingService.showLoadingSpinner = true;
    }
    const loggedInObject = this._localStore.getItem('logged-data');
    const data = {
      request: {
        companyid: loggedInObject.companyid,
        deviceid: loggedInObject.deviceid,
        token: this._dataService.getToken()
      }
    };
    this._roleService.getRoles(data).subscribe((result: any) => {
      if (result.response.errormessage === 'Success' || result.response.errormessage === '') {
        this.roles = result.response.roles.ttRoles;
        const that = this;
        this.isListEmpty = this.roles.length === 0;

        this.roles.forEach((ele: any, key: any) => {
          that.roles[key].roles_id = ele.Roles_id;
          that.roles[key].roles_active = ele.Roles_Active;
          delete that.roles[key].Roles_id;
          delete that.roles[key].Roles_Active;
        });

        this.roles_groups = result.response.roles_group.ttrolls_group;

        const rolesArray = this.roles;
        const rolesGroupArray = this.roles_groups;

        // filtering the array based the role
        this.fileteredRoles = Array.from(rolesGroupArray.reduce(
          (red: any, elem: any) => {
            if (red.has(elem.roles_id)) { red.get(elem.roles_id).roles_group.push(elem); }
            return red;
          },
          new Map(rolesArray.map(elem => [elem.roles_id, { ...elem, roles_group: [] }]))
        ).values());

        this.dataSource.data = this.fileteredRoles;

        this.realoadDataTableResource();
      } else if (result.response.errormessage === 'Invalid Session') {
        this._dataService.tokenExpired();
        this.isListEmpty = false;
      }
      this._loadingService.showLoadingSpinner = false;
    }, error => {
      this._loadingService.showLoadingSpinner = false;
    });
  }

  /**
   * function used to delete existing group
   */
  deleteGroup(element) {
    const postData = {
      request: {
        companyid: this.loggedUser.companyid,
        roleid: element.roles_id,
        token: this._loginService.isLoggedInToken(),
        deviceid: this.loggedUser.deviceid
      }
    };
    this._groupService.deleteRole(postData).subscribe((res: any) => {
      if (res.response.statuscode === 200) {
        this._notifyService.showSuccess(res.response.errormessage, 'Success');
        this.getAllRolesList();
      } else {
        this._notifyService.showError(res.response.errormessage, 'Error');
      }
    }, error => {
      this._notifyService.showError(error.message, 'Error');
    });
  }


  /**
   * function used for filter data form table on input keyup
   * @param value = input value
   */
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
   * function used for open edit group dialog
   */
  openAllGroupsModal(element) {
    this.dialog.open(ViewAllGroupsComponent, {
      data: {
        name: element.roles_name,
        groups: element.roles_group
      }
    });
  }
}
