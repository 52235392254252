<!--<div class="pb-2 d-flex border-bottom align-items-center justify-content-between">-->
<!--    <h5 class="modal-title mb-0" mat-dialog-title>Delete Confirmation-->
<!--    </h5>-->
<!--    <button type="button" class="close" mat-dialog-close>-->
<!--        <span class="material-icons">highlight_off</span>-->
<!--    </button>-->
<!--</div>-->
<mat-dialog-content class="py-4">

    <div class="text-center">
        <h4>{{literals.LblDeletelab}}</h4>
    </div>

    <div class="modal-actions text-center">
        <button type="button" (click)="delete()" class="btn btn-red btn-radius">{{literals.LblDelete}}</button>
        <button type="button" class="btn btn-green btn-radius" mat-dialog-close>{{literals.LblCancel}}</button>
    </div>
</mat-dialog-content>
