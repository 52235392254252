import { Injectable } from '@angular/core';
import { DataService } from '../shared-module/services/data.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class RolesService {
    configuration = {
        API_ENDPOINT: environment.apiUrl,
    };

    constructor(
        private dataService: DataService,
        private _http: HttpClient
    ) {
    }

    public addGroup(body) {
        return this.dataService.postDataWithHeader(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/addgroups', body
        );
    }
    public editGroup(body) {
        return this.dataService.postDataWithHeader(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/editgroups', body
        );
    }
    public deleteGroup(body) {
        return this.dataService.postDataWithHeader(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/deletegroups', body
        );
    }

    public getRoles(body) {
        return this.dataService.postDataWithHeader(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/getroles', body
        );
    }

}
