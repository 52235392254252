<div class="login-form-wrap">
    <div class="container-fluid">
       <div class="row no-padding">
          <div class="col-md-6 login-col d-flex align-items-center justify-content-center">
             <div class="login-page-content">
                <header class="login-header">
                   <div class="login-logo text-center">
                      <img src="../../assets/images/Logo.svg" alt="RPM">
                   </div>
                   <div class="login-heading text-center">
                      <h2>{{literals.LblLoginwelcome}}</h2>
                      <h4>{{literals.LblLoginaccess}}</h4>
                   </div>
                </header>
                <div class="row justify-content-center">
                   <div class="col-md-7">
                      <div class="login-box">
                         <form class="form-horizontal" [formGroup]="loginForm">
                            <div class="form-group" [ngClass]="displayFieldCss('company_name')">
                               <label class="main-label control-label required" for="company_name">{{literals.LblCompany}}</label>
                               <select name="company_name" id="company_name" class="custom-select" formControlName="company_name">
                                  <option *ngFor="let company of companies" [ngValue]="company.companyCode">
                                      {{ company.companyName }}
                                    </option>
                              </select>
                               <app-field-error-display [displayError]="isFieldValid('company_name')" errorMsg="{{literals.LblUserId}}">
                               </app-field-error-display>
                            </div>
                            <div class="form-group" [ngClass]="displayFieldCss('user_id')">
                               <label class="main-label control-label required" for="user_id">{{literals.LblUserId}}</label>
                               <input type="text" class="form-control" id="user_id" placeholder="{{literals.LblInsideUserId}}" formControlName="user_id">
                               <app-field-error-display [displayError]="isFieldValid('user_id')" errorMsg="{{literals.LblUserIdErrorMessage}}">
                               </app-field-error-display>
                            </div>
                            <div class="form-group" [ngClass]="displayFieldCss('password')">
                               <label class="main-label control-label required" for="password">{{literals.LblPassword}}</label>
                               <input type="password" class="form-control" id="password" placeholder="{{literals.LblInsidePassword}}" formControlName="password">
                               <app-field-error-display [displayError]="isFieldValid('password')" errorMsg="{{literals.LblPasswordError}}">
                               </app-field-error-display>
                            </div>
                            <div class="custom-control custom-checkbox mb-4">
                               <input type="checkbox" class="custom-control-input" id="rememberMe" formControlName="rememberMe">
                               <label class="custom-control-label" for="rememberMe" >{{literals.LblRememberme}}</label>
                            </div>
                            <button class="btn btn-block btn-icon btn-blue btn-md btn-radius" [disabled]="!loginForm.valid" (click)="onSubmit()">
                                <div *ngIf="showLoader" class="loader_3">
                                    <div class="ball-pulse">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                                <span *ngIf="!showLoader" style="display: inherit;"><span class="material-icons icon">login</span>
                                {{literals.LblLogin}}</span>
                            </button>
                            
                         </form>
                         <div class="text-center mt-2 forgot-password">
                            <a [routerLink]="['/forgot-password']">{{literals.LblForgotPassword}}</a>
                         </div>
                      </div>
                   </div>
                </div>
                <footer class="login-footer mt-4">
                   <div class="row align-items-center">
                      <div class="col-md-6 rights-res">
                         &copy; {{literals.LblAllRights}}
                      </div>
                      <div class="col-md-6 d-flex justify-content-end">
                         <div class="language-dropdown">
                            <span class="material-icons">language</span>
                           <form [formGroup]="languageForm">
                              <select name="language_name" id="language_name" class="custom-select" formControlName="language_name" (change)="setUpSystemData()">
                                 <option *ngFor="let language of languages" [ngValue]="language.languageCode">
                                    {{ language.languageName }}
                                 </option>
                              </select>
                           </form>
                         </div>
                      </div>
                   </div>
                </footer>
             </div>
          </div>

          <div class="col-md-6 login-col">
             <div class="login-page-infographic d-flex align-items-center justify-content-center">
                <div class="pattern-1"></div>
                <div class="info-content-wrap">
                   <img src="../../assets/images/login-graphic.png" alt="login">
                   <div class="info-content">
                      {{literals.LblAccessControl}}
                   </div>
                </div>
                <div class="pattern-2"></div>
             </div>
          </div>
       </div>
    </div>
    <!-- .container-fluid ends here -->
 </div>
 <!-- .login-form-wrap ends here -->
