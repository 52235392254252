import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { LoginService } from '../login/login.service';
import {DashboardService} from './dashboard.service';
import {DataService} from '../shared-module/services/data.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {LoadingService} from '../shared-module/services/loading.service';

export interface LoggedUsers {
  User_Id: number;
  User_Name: string;
  LoginTime: Date;
  LogoutTime: Date;
  Duration: number;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  data: LoggedUsers[] = [];
  public displayedColumns = ['id', 'User_Id', 'User_Name', 'LoginTime', 'LogoutTime', 'Duration'];
  public dataSource = new MatTableDataSource<LoggedUsers>(this.data);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selection = new SelectionModel<LoggedUsers>(true, []);
  loggedUser;
  dashboardData: any;
  usedGroupsPercentage;
  rolesPercentage;
  activeUsersPercentage;
  loggedUsers = [];
  isListEmpty = false;
  literals: any;
  constructor(private loginService: LoginService, private dashboardService: DashboardService,
              private dataService: DataService, private loadingService: LoadingService,
              private cd: ChangeDetectorRef) {
    this.loggedUser = this.dataService.getLoggedUser();
  }

  ngOnInit(): void {
    this.getDashboard();
    this.literals = this.loginService.getLiterals();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  /**
   * function used for filter data form table on input keyup
   * @param value = input value
   */
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  /**
   * function used for get profile
   */
  getDashboard() {
    this.loadingService.showLoadingSpinner = true;
    const postData = {
      request: {
        companyid: this.loggedUser.companyid,
        token: this.loginService.isLoggedInToken(),
        deviceid: this.loggedUser.deviceid,
      }
    };
    this.dashboardService.getDashboardData(postData).subscribe((res: any) => {
      this.loadingService.showLoadingSpinner = false;
      if (res.response.errormessage === 'Success' || res.response.errormessage === '') {
        this.dashboardData = res.response;
        this.activeUsersPercentage = (this.dashboardData.activeuser / this.dashboardData.totaluser) * 100;
        this.usedGroupsPercentage = ((this.dashboardData.totalgroup - this.dashboardData.usedgroup) / this.dashboardData.totalgroup) * 100;
        this.rolesPercentage = ((this.dashboardData.totalrolls - this.dashboardData.usedrolls) / this.dashboardData.totalrolls) * 100;
        // if (this.dashboardData.usedgroup === 0 || this.dashboardData.totalgroup) {
        //   this.usedGroupsPercentage = 0;
        // }
        // if (this.dashboardData.usedrolls === 0 || this.dashboardData.totalrolls) {
        //   this.rolesPercentage = 0;
        // }
        this.loggedUsers = res.response.listloggedinusers.ttLoggedIn_users;
        this.isListEmpty = this.loggedUsers.length === 0;
        this.dataSource.data = this.loggedUsers;
      } else if (res.response.errormessage === 'Invalid Session') {
        this.dataService.tokenExpired();
      }
    }, error => {
      this.isListEmpty = true;
      this.loadingService.showLoadingSpinner = false;
    });
  }

}
