import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {GroupService} from '../../../groups/group.service';
import {DataService} from '../../services/data.service';
import {LoginService} from '../../../login/login.service';
import {NotificationService} from '../../../toaster/notification.service';

export interface Fields {
  id: number;
  group_name: string;
  active: string;
  group_functions: [];
}

@Component({
  selector: 'app-add-edit-group',
  templateUrl: './view-all-groups.component.html',
  styleUrls: ['./view-all-groups.component.css']
})
export class ViewAllGroupsComponent implements OnInit {
  data: any;

  constructor(private dialogRef: MatDialogRef<ViewAllGroupsComponent>,
              @Inject(MAT_DIALOG_DATA) dialogData) {
    this.data = dialogData;
  }

  ngOnInit(): void {

  }

}
