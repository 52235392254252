import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {GroupService} from '../../../groups/group.service';
import {DataService} from '../../services/data.service';
import {LoginService} from '../../../login/login.service';
import {NotificationService} from '../../../toaster/notification.service';
import {LoadingService} from '../../services/loading.service';

export interface Fields {
  id: number;
  group_name: string;
  active: string;
  group_functions: [];
}

@Component({
  selector: 'app-add-edit-group',
  templateUrl: './add-edit-group.component.html',
  styleUrls: ['./add-edit-group.component.css']
})
export class AddEditGroupComponent implements OnInit, AfterViewChecked {
  type: 'create' | 'edit' = 'create';
  form: FormGroup;
  submitted = false;
  data: any;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  functionCtrl = new FormControl();
  filteredFunctions: Observable<any[]>;
  functions: any[] = [];
  allFunctions: any[] = [];
  allFunctions_t: any[] = [];
  loggedUser;
  activeBtnChecked = false;
  literals: any;
  activeCheckBoxElement:string;

  @ViewChild('functionsInput') functionsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('auto') trigger;
  constructor(private dialogRef: MatDialogRef<AddEditGroupComponent>,
              @Inject(MAT_DIALOG_DATA) dialogData, private fb: FormBuilder,
              private groupService: GroupService, private dataService: DataService,
              private loginService: LoginService, private notifyService: NotificationService,
              private loadingService: LoadingService, private cd: ChangeDetectorRef) {
    this.loggedUser = this.dataService.getLoggedUser();
    this.data = dialogData;
    // this.allFunctions = this.data.functions;
    this.getGroupFunctionsList();
    if (this.data.defaultData) {
      this.type = 'edit';
      this.functions = this.data.defaultData.group_functions;
    }

  }

  ngOnInit(): void {
    localStorage.removeItem('allFunctions');
    this.literals = this.loginService.getLiterals();
    this.form = this.fb.group({
      groupid: '',
      groupname: ['', Validators.required],
      active: ['no', Validators.required],
      group_functions: [[]]
    });
    this.activeCheckBoxElement = ".custom-control.custom-switch .custom-control-label .activeInactive";
    setTimeout(() => {
      document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblInActive);
    }, 200);

    if (this.type === 'edit') {
      this.form = this.fb.group({
        groupid: [this.data.defaultData.group_id],
        groupname: [this.data.defaultData.group_name, Validators.required],
        active: [this.data.defaultData.group_active, Validators.required],
        group_functions: [this.data.defaultData.group_functions]
      });
      this.activeBtnChecked = (this.data.defaultData.group_active === 'yes');

      setTimeout(() => {
        if (this.activeBtnChecked) {
          document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblActive);
        } else {
          document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblInActive);
        }
      }, 200);
    }
  }


  ngAfterViewChecked() {
    this.literals = this.loginService.getLiterals();
    this.cd.detectChanges();
  }


  /**
   * function used for get group functions list
   */
  getGroupFunctionsList() {
    const postData = {
      request: {
        companyid: this.loggedUser.companyid,
        token: this.loginService.isLoggedInToken(),
        deviceid: this.loggedUser.deviceid,
      }
    };
    this.groupService.getGroupsList(postData).subscribe((res: any) => {
      if (res.response.errormessage === 'Success' || res.response.errormessage === '') {
        this.allFunctions = res.response.functions.tt_functions;
        
      } else if (res.response.errormessage === 'Invalid Session') {
        this.dataService.tokenExpired();
      }
      let menuId = this.literals.LblMenuID;
      this.allFunctions.forEach(obj=> {
        obj.function_name = obj.function_name +" ("+menuId+": " +obj.menu_id+")";
     });

     this.allFunctions.sort(function(a, b) {
      return a.function_name.localeCompare(b.function_name);
    });

     localStorage.setItem('allFunctions', JSON.stringify(this.allFunctions));
      this.filteredFunctions = this.functionCtrl.valueChanges.pipe(
          startWith(null),
          map((value: string | null) => value ? this._filter(value) : this.allFunctions.slice()));
    });
  }

  add(event): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      const index = this.functions.findIndex(x => x === value.trim());
      if (index === -1) {
        this.functions.push(value.trim());
        this.form.get('group_functions').setValue(this.functions);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.functionCtrl.setValue(null);
  }

  remove(group: string): void {
    const index = this.functions.indexOf(group);

    if (index >= 0) {
      this.functions.splice(index, 1);
      this.form.get('group_functions').setValue(this.functions);
    }
    this.addElementToArray(group);
  }

  selected(event): void {
    if(this.functions) {
      const index = this.functions.findIndex(x => x.function_id === event.option.value.function_id);
      if (index === -1) {
        this.functions.push(event.option.value);
        this.form.get('group_functions').setValue(this.functions);
      }
    }
    //
    this.removeElementFromArray(event.option.value);
    console.log(this.functions);
    
    this.functionsInput.nativeElement.value = '';
    this.functionCtrl.setValue(null);
    
    const chipElement = document.querySelector('#functionToGroup');
    if (chipElement instanceof HTMLElement) {
      chipElement.blur();
      setTimeout(() => { chipElement.focus() }, 1);
    } 
    
    
  }

  addElementToArray(group) {
    let allFunctions = JSON.parse(localStorage.getItem('allFunctions'));
    var index = allFunctions.findIndex(function (item, i) {
      return item.function_id === group.function_id
    });
    // delete group.group_id;
    let index_t = this.allFunctions.findIndex(function (item, i) {
      return item.function_id === group.function_id
    });
    
    if (index_t === -1) {
      this.allFunctions.splice(index, 0, group);
      this.filteredFunctions = this.functionCtrl.valueChanges.pipe(
        startWith(null),
        map((value: string | null) => value ? this._filter(value) : this.allFunctions.slice()));
    }
  }

  removeElementFromArray(group) {
    let allFunctions = JSON.parse(localStorage.getItem('allFunctions'));
    var index = allFunctions.findIndex(function (item, i) {
      return item.function_id === group.function_id
    });

    if(this.allFunctions.length === 1) {
      this.allFunctions.splice(0, 1);
    }  else {
      this.allFunctions.splice(index, 1);  
    }
    this.filteredFunctions = this.functionCtrl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => value ? this._filter(value) : this.allFunctions.slice()));
  }

  private _filter(value): any[] {
    let filterValue;
    if (value.function_name) {
      filterValue = value.function_name.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }
    return this.allFunctions.filter(group => group.function_name.toLowerCase().match(filterValue));
  }

  /**
   * function user for toggle active status
   * @param checkbox = checkbox ele
   */
  setActive(checkbox) {

    if (checkbox.checked) {
      document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblActive);
    } else {
      document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblInActive);
    }

    if (checkbox.checked) {
      this.form.get('active').setValue('yes');
    } else {
      this.form.get('active').setValue('no');
    }
  }

  /**
   * function used to create group
   */
  createGroup() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    } else {
      this.loadingService.showLoadingSpinner = true;
      this.form.value.group_functions.map(item => {
        Object.assign(item, {
          group_id: ''
        });
      });
      const postData = {
        request: {
          companyid: this.loggedUser.companyid,
          token: this.loginService.isLoggedInToken(),
          deviceid: this.loggedUser.deviceid,
          groupname: this.form.value.groupname,
          active: this.form.value.active,
          group_functions: this.form.value.group_functions
        }
      };
      this.groupService.addGroup(postData).subscribe((res: any) => {
        this.loadingService.showLoadingSpinner = false;
        if (res.response.statuscode === 200) {
          this.notifyService.showSuccess(res.response.errormessage, 'Success');
          this.dialogRef.close({
            created: true
          });
        } else if (res.response.errormessage === 'Invalid Session') {
          this.dataService.tokenExpired();
        } else {
          this.notifyService.showError(res.response.errormessage, 'Error');
        }
      }, error => {
        this.loadingService.showLoadingSpinner = false;
        this.notifyService.showError(error.message, 'Error');
      });
    }
  }

  /**
   * function used to edit group
   */
  editGroup() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    } else {
      this.loadingService.showLoadingSpinner = true;
      if(this.form.value.group_functions) {
        this.form.value.group_functions.map(item => {
          Object.assign(item, {
            group_id: this.form.value.groupid
          });
        });
      } else {
        this.form.value.group_functions = [];
      }

      const postData = {
        request: {
          companyid: this.loggedUser.companyid,
          token: this.loginService.isLoggedInToken(),
          deviceid: this.loggedUser.deviceid,
          groupid: this.form.value.groupid,
          groupname: this.form.value.groupname,
          active: this.form.value.active,
          group_functions: this.form.value.group_functions
        }
      };
      this.groupService.editGroup(postData).subscribe((res: any) => {
        this.loadingService.showLoadingSpinner = false;
        if (res.response.statuscode === 200) {
          this.notifyService.showSuccess(res.response.errormessage, 'Success');
          this.dialogRef.close({
            updated: true
          });
        } else if (res.response.errormessage === 'Invalid Session') {
          this.dataService.tokenExpired();
        } else {
          this.notifyService.showError(res.response.errormessage, 'Error');
        }
      }, error => {
        this.loadingService.showLoadingSpinner = false;
        this.notifyService.showError(error.message, 'Error');
      });
    }
  }

  /**
   * function used when submit form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    } else {
      if (this.type === 'edit') {
        this.editGroup();
      } else {
        this.createGroup();
      }
    }
  }

  onFocus() {
    
    this.trigger.showPanel = true;
    console.log(this.trigger.showPanel)
    // this.trigger._onChange(""); 
    // this.trigger.openPanel();
  }
  
  /**
   * function used when cancel form
   */
  cancel(){
    this.dialogRef.close({
      cancel: true
    });
  }

}
