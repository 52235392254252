<!--modal-header start-->
<div class="pb-2 d-flex border-bottom align-items-center justify-content-between">
    <h5 class="modal-title mb-0" mat-dialog-title>
        <span *ngIf="type === 'create'" id="addNewGroup" (click)="onFocus()">{{literals.LblAddNewGroup}}</span>
        <span *ngIf="type === 'edit'" id="addNewGroup">{{literals.LblEditGroup}}</span>
    </h5>
    <button type="button" class="close" (click)="cancel()">
        <span class="material-icons">highlight_off</span>
    </button>
</div>
<!--modal-header end-->

<!--modal-content body start-->
<mat-dialog-content class="py-4">
    <form [formGroup]="form"  (ngSubmit)="onSubmit()">
        <div class="row d-flex">
            <div class="col-md-8">
                <div class="form-group">
                    <label class="main-label control-label required-after-login">{{literals.LblGroupName}}</label>
                    <input type="text" formControlName="groupname" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('groupname').errors }" required/>
                    <div *ngIf="submitted && form.get('groupname').errors" class="invalid-feedback">
                        <div *ngIf="form.get('groupname').errors.required">{{literals.LblAddNewGroupError}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="custom-control custom-switch mt-4">
                    <input #checkbox (change)="setActive(checkbox)" type="checkbox" class="custom-control-input" id="customSwitch1" [checked]="activeBtnChecked">
                    <label class="custom-control-label" for="customSwitch1">
                        <span class="activeInactive">&nbsp;</span>
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="main-label">{{literals.LblFunctionGroup}}</label>
            <mat-form-field class="example-chip-list d-block">
                <mat-chip-list #chipList aria-label="Fruit selection">
                    <mat-chip
                            *ngFor="let function of functions"
                            [selectable]="selectable"
                            [removable]="removable"
                            (removed)="remove(function)">
                        {{function.function_name}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input #functionsInput
                           [formControl]="functionCtrl"
                           [matAutocomplete]="auto"
                           [matChipInputFor]="chipList"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           (matChipInputTokenEnd)="add($event)"
                           #trigger="matAutocompleteTrigger"
                           (click)='trigger.openPanel()'>
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let function of filteredFunctions | async" [value]="function">
                        {{function.function_name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <!--modal-content action buttons-->
        <div class="modal-actions text-center">
            <button *ngIf="type === 'create'" type="submit" class="btn btn-blue btn-radius">{{literals.LblCreate}}</button>
            <button *ngIf="type === 'edit'" type="submit" class="btn btn-blue btn-radius">Update</button>
            <button (click)="cancel()" type="button" class="btn btn-gray btn-radius">{{literals.LblCancel}}</button>
        </div>
        <!--modal-content action buttons-->
    </form>
</mat-dialog-content>
<!--modal-content body end-->
