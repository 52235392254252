import {AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LoadingService} from '../../services/loading.service';
import {LoginService} from '../../../login/login.service';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmationComponent implements OnInit, AfterViewChecked {
  literals;
  constructor(private dialogRef: MatDialogRef<DeleteConfirmationComponent>,
              @Inject(MAT_DIALOG_DATA) dialogData,
              private loginService: LoginService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.literals = this.loginService.getLiterals();
  }

  ngAfterViewChecked() {
    this.literals = this.loginService.getLiterals();
    this.cd.detectChanges();
  }

  delete() {
    this.dialogRef.close({
      delete: true
    });
  }

}
