<div class="pb-2 d-flex border-bottom align-items-center justify-content-between">
        <h5 class="modal-title mb-0" mat-dialog-title>
            <span *ngIf="type === 'create'">{{literals.LblAddNewRole}}</span>
            <span *ngIf="type === 'edit'">{{literals.LblAddEditroll}}</span>
        </h5>
        <button type="button" class="close" (click)="cancel()">
            <span class="material-icons">highlight_off</span>
        </button>
    </div>
    <mat-dialog-content class="py-4">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row d-flex">
                <div class="col-md-8">
                    <div class="form-group">
                        <label class="main-label control-label required-after-login">{{literals.LblRoleName}}</label>
                        <input #role_name type="text" formControlName="role_name" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && form.get('role_name').errors }" required />
                        <div *ngIf="submitted && form.get('role_name').errors" class="invalid-feedback">
                            <div *ngIf="form.get('role_name').errors.required">{{literals.LblRoleNameError}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                        <div class="custom-control custom-switch mt-4">
                            <input #checkbox (change)="setActive(checkbox)" type="checkbox" class="custom-control-input" id="customSwitch1" [checked]="activeBtnChecked">
                            <label class="custom-control-label" for="customSwitch1">
                                <span class="activeInactive">&nbsp;</span>
                            </label>
                        </div>
                    </div>
            </div>
    
            <div class="form-group">
                <label class="main-label">{{literals.LblSelectGroup}}</label>
                <mat-form-field class="example-chip-list d-block">
                    <mat-chip-list #chipList aria-label="Fruit selection">
                            <mat-chip
                                    *ngFor="let group of groups"
                                    [selectable]="selectable"
                                    [removable]="removable"
                                    (removed)="remove(group)">
                                {{group.group_name}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input #groupsInput
                                    [formControl]="groupCtrl"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event)"
                                   #trigger="matAutocompleteTrigger"
                                   (click)='trigger.openPanel()' required>
                        </mat-chip-list>
    
                    <!-- <div *ngIf="submitted && groups.length === 0" class="invalid-feedback">
                        <div>Please select atleast one group.</div>
                    </div> -->
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let group of filteredGroups | async" [value]="group">
                            {{group.group_name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
    
    
            <div class="modal-actions text-center">
                <button *ngIf="type === 'create'" type="submit" class="btn btn-blue btn-radius">{{literals.LblCreate}}</button>
                <button *ngIf="type === 'edit'" type="submit" class="btn btn-blue btn-radius">Update</button>
                <button type="button" class="btn btn-gray btn-radius" (click)="cancel()">{{literals.LblCancel}}</button>
            </div>
        </form>
    </mat-dialog-content>
