import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DataService} from '../shared-module/services/data.service';
import {LoginService} from '../login/login.service';
import {NotificationService} from '../toaster/notification.service';
import {LoadingService} from '../shared-module/services/loading.service';
import {ProfileService} from '../edit-profile/profile.service';
import {AddEditUserComponent} from '../shared-module/components/add-edit-user/add-edit-user.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {UserService} from '../users/user.service';
import { ForgotPasswordService } from '../forgot-password/forgot-password.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {
  type: 'create' | 'edit' = 'edit';
  form: FormGroup;
  submitted = false;
  data: any;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  functionCtrl = new FormControl();
  loggedUser;
  userDetail: any;
  pattern = {
    phone: '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$'
  };
  literals;

  constructor(private fb: FormBuilder, private dataService: DataService,
              private _router: Router,
              private loginService: LoginService, private notifyService: NotificationService,
              private loadingService: LoadingService, private profileService: ProfileService,
              private _forgotPasswordService: ForgotPasswordService,
              private cd: ChangeDetectorRef) {
    this.loggedUser = this.dataService.getLoggedUser();

    console.log(this.loginService.getLiterals());
  }

  ngOnInit(): void {
    this.literals = this.loginService.getLiterals();

    this.form = this.fb.group({
      password: ['', [Validators.required]],
      c_password: ['']
    }, { validator: this.checkPasswords });
  }

  ngAfterViewChecked() {
    this.literals = this.loginService.getLiterals();
    this.cd.detectChanges();
  }

  /**
   * function used to edit profile
   */
  editProfile() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    } else {
      this.loadingService.showLoadingSpinner = true;
      const password = this.form.value.password;
      this._forgotPasswordService.resetpassword(password,this.loggedUser.companyid, this.loggedUser.username, ).subscribe((res: any) => {
        this.loadingService.showLoadingSpinner = false;
        if (res['response'].errormessage != '' && res['response'].statuscode != 200) {
          this.notifyService.showError(res['response'].errormessage, "Error");
        } else {
          this._router.navigate(['dashboard']);
          this.notifyService.showSuccess(res['response'].errormessage, "Success");
        }
       
      
      }, error => {
        this.loadingService.showLoadingSpinner = false;
        this.notifyService.showError(error.message, 'Error');
      });
    }
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.c_password.value;

    return pass === confirmPass ? null : { notSame: true }
  }
}
