import { Injectable } from '@angular/core';
import { DataService } from '../shared-module/services/data.service';
import { environment } from '../../environments/environment';

@Injectable()
export class DashboardService {
    configuration = {
        API_ENDPOINT : environment.apiUrl,
    };

    constructor(
        private dataService: DataService ) {
    }

    public getDashboardData(body) {
        return this.dataService.postData(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/getdashboarddata', body
        );
    }

}
