import {AfterContentInit, AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoginService} from '../login/login.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl
} from '@angular/forms';
import {LoadingService} from '../shared-module/services/loading.service';
import {LocalStoreService} from '../shared-module/services/local-store.service';
import {SystemSetupService} from '../system-setup.service';
import {ForgotPasswordService} from './forgot-password.service';
import { NotificationService } from '../toaster/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, AfterViewChecked {
  private _errorMessage: string;
  showLoginForm = true;
  showOtpForm = false;
  showChangePassForm = false;
  literals: any;
  langId: any;
  languageForm: FormGroup;
  otpForm: FormGroup;
  changePasswordForm: FormGroup;
  companies = [];
  loginForm: FormGroup;
  user_id:any;
  company_id:any;
  languages = [
    {
      languageName: 'English',
      languageCode: 0
    },
    {
      languageName: 'Spanish',
      languageCode: 1
    }
  ];
  change_lang_id:number;
  lang_id:any;
  showLoader = false;

  constructor(private loginService: LoginService, private cd: ChangeDetectorRef,
              private formBuilder: FormBuilder, private loadingService: LoadingService,
              private store: LocalStoreService,
              private _forgotPasswordService: ForgotPasswordService,
              private _notifyService: NotificationService,
              private _router: Router,
              private _systemSetupService: SystemSetupService) {

    this.languageForm = this.formBuilder.group({
      language_name: [this.languages[0].languageCode, []]
    });
  }

  ngOnInit(): void {
    this.literals = this.loginService.getLiterals();
    this.loginFornBuilder();
    this.languageFormBuilder();
    this.setUpSystemData();
  }

  ngAfterViewChecked() {
    this.literals = this.loginService.getLiterals();
    this.cd.detectChanges();
  }
  
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }


  loginFornBuilder(companyDefault = null) {
    this.loginForm = this.formBuilder.group({
      company_name: [companyDefault, [Validators.required]],
      user_id: [null, Validators.required]
    });

    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      c_password: ['']
    }, { validator: this.checkPasswords });

    
    

    this.otpForm = this.formBuilder.group({
      otp: [null, Validators.required]
    });
  }

  displayFieldCssForOtp(field: string) {
    return {
      'has-error': this.isFieldValidForOtp(field),
      'has-feedback': this.isFieldValidForOtp(field)
    };
  }

  displayFieldCssForOtpChangePasswordForm(field: string) {
    return {
      'has-error': this.isFieldValidChangePasswordForm(field),
      'has-feedback': this.isFieldValidChangePasswordForm(field)
    };
  }

  isFieldValid(field: string) {
    return !this.loginForm.get(field).valid && this.loginForm.get(field).touched;
  }

  isFieldValidChangePasswordForm(field: string) {
    // this.changePasswordForm;
    return !this.changePasswordForm.get(field).valid && this.changePasswordForm.get(field).touched;
  }

  isFieldValidForOtp(field: string) {
    return !this.otpForm.get(field).valid && this.otpForm.get(field).touched;
  }

  languageFormBuilder() {
    if (!localStorage.getItem('lang_id')) {
      this.change_lang_id = this.languages[0].languageCode;
    } else {
      this.change_lang_id = parseInt(localStorage.getItem('lang_id'));
    }
    this.languageForm = this.formBuilder.group({
      language_name: [this.change_lang_id, []]
    });

  }


  setUpSystemData() {
    this.lang_id = this.languageForm.value.language_name;
    localStorage.setItem('lang_id', this.lang_id);
    this.loadingService.showLoadingSpinner = true;
    this._systemSetupService.getSystemsetup(this.lang_id).subscribe((result: any) => {
        if (result.response.errormessage === 'Success') {
          this.loadingService.showLoadingSpinner = false;
          this.companies = result.response.companies.company;
          this.loginFornBuilder(this.companies[0].companyCode);
          this.store.setItem('systemSetupData', result.response.setupdata.setup_info);
          this.literals = this.loginService.getLiterals();
        }},
      error => {
        this.loadingService.showLoadingSpinner = false;
      });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.company_id = this.loginForm.value.company_name;
      this.user_id = this.loginForm.value.user_id;
      this.showLoader = true;
      this._forgotPasswordService.sendOtp(this.company_id, this.user_id).subscribe(result => {
        if (result['response'].errormessage != '' && result['response'].statuscode != 200) {
          this._notifyService.showError(result['response'].errormessage, "Error");
        } else {
          this.showOtpForm = true;
          this.showLoginForm = false;
          this.showChangePassForm = false;
          this._notifyService.showSuccess(result['response'].errormessage, "Success");
        }
        this.showLoader = false;
      },
        error => {
          if (error.status) {
            this._resetForm();
            this._errorMessage = "Something went wrong on server.";
            this._notifyService.showError(this._errorMessage, "Error")
          }
          this.showLoader = false;
        }
      );
    } else {
      this.validateAllFormFields(this.loginForm);
    }
  }

  onSubmitVerifyOtp() {
    if (this.otpForm.valid) {
      const otp = this.otpForm.value.otp;
      this.showLoader = true;
      this._forgotPasswordService.verifyotp(otp, this.company_id, this.user_id).subscribe(result => {
        console.log(result);
        if (result['response'].errormessage != '' && result['response'].statuscode != 200) {
          this._notifyService.showError(result['response'].errormessage, "Error");
        } else {
          this.showOtpForm = false;
          this.showLoginForm = false;
          this.showChangePassForm = true;
          // this._notifyService.showSuccess(result['response'].errormessage, "Success");
        }
        this.showLoader = false;
      },
        error => {
          if (error.status) {
            this._resetForm();
            this._errorMessage = "Something went wrong on server.";
            this._notifyService.showError(this._errorMessage, "Error")
          }
          this.showLoader = false;
        }
      );
    } else {
      this.validateAllFormFields(this.loginForm);
    }
  }

  onSubmitPassword() {
    if (this.changePasswordForm.valid) {
      const password = this.changePasswordForm.value.password;
      this.showLoader = true;
      this._forgotPasswordService.resetpassword(password, this.company_id, this.user_id).subscribe(result => {
        console.log(result);
        if (result['response'].errormessage != '' && result['response'].statuscode != 200) {
          this._notifyService.showError(result['response'].errormessage, "Error");
        } else {
          this._notifyService.showSuccess(result['response'].errormessage, "Success");
          this.showOtpForm = false;
          this.showLoginForm = true;
          this.showChangePassForm = false;
          this._router.navigate(['login']);
        }
        this.showLoader = false;
      },
        error => {
          if (error.status) {
            this._resetForm();
            this._errorMessage = "Something went wrong on server.";
            this._notifyService.showError(this._errorMessage, "Error")
          }
          this.showLoader = false;
        }
      );
    } else {
      this.validateAllFormFields(this.loginForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private _resetForm(): void {
    // this.loginForm.reset();
    this.loginForm = this.formBuilder.group({
      company_name: [this.companies[0].companyCode, [Validators.required]],
      user_id: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.c_password.value;

    return pass === confirmPass ? null : { notSame: true }
  }

}
