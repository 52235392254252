import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { NotificationService } from '../toaster/notification.service';
import { Router } from '@angular/router';
import { SystemSetupService } from '../system-setup.service';
import { LoadingService } from '../shared-module/services/loading.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import {LocalStoreService} from '../shared-module/services/local-store.service';
import { ProfileService } from '../edit-profile/profile.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  invalidInputField = true;
  loginForm: FormGroup;
  languageForm: FormGroup;
  showLoader = false;
  private _formErrors: any;
  private _submitted: boolean;
  private _errorMessage: string;
  literals: any;
  companies: any;

  languages = [
    {
      languageName: 'English',
      languageCode: 0
    },
    {
      languageName: 'Spanish',
      languageCode: 1
    }
  ];
  lang_id:any;
  change_lang_id:number;

  constructor(
    private formBuilder: FormBuilder,
    private _loginService: LoginService,
    private _notifyService: NotificationService,
    private _router: Router, private store: LocalStoreService,
    public _loadingService: LoadingService,
    private _profileService: ProfileService,
    private _systemSetupService: SystemSetupService) {
      this.loginFornBuilder();
      this.languageFormBuilder();
  }

  languageFormBuilder() {
    if (!localStorage.getItem('lang_id')) {
      this.change_lang_id = this.languages[0].languageCode;
    } else {
      this.change_lang_id = parseInt(localStorage.getItem('lang_id'));
    }

    this.languageForm = this.formBuilder.group({
      language_name: [this.change_lang_id, []]
    });

  }

  loginFornBuilder(companyDefault = null) {
    const userid =  localStorage.getItem('userid');
    const company_id =  localStorage.getItem('company_id');
    let setDefaultUserId = null;
    let rememberMe = false;
    if(userid) {
      setDefaultUserId = userid;
      companyDefault = company_id;
      rememberMe = true;
    }
    this.loginForm = this.formBuilder.group({
      company_name: [companyDefault, [Validators.required]],
      user_id: [setDefaultUserId, Validators.required],
      password: [null, Validators.required],
      rememberMe: [rememberMe]
    });
  }

  ngOnInit(): void {
    
    this.setUpSystemData();
    this.literals = this._loginService.getLiterals();
  }

  isFieldValid(field: string) {
    return !this.loginForm.get(field).valid && this.loginForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  setUpSystemData() {
    this.lang_id = this.languageForm.value.language_name;
    localStorage.setItem('lang_id', this.lang_id);
    this._loadingService.showLoadingSpinner = true;
    this._systemSetupService.getSystemsetup(this.lang_id).subscribe((result: any) => {
        if (result.response.errormessage === 'Success') {
          this._loadingService.showLoadingSpinner = false;
          this.companies = result.response.companies.company;
          this.loginFornBuilder(this.companies[0].companyCode);
          this.store.setItem('systemSetupData', result.response.setupdata.setup_info);
          this.literals = this._loginService.getLiterals();
        }},
      error => {
        this._loadingService.showLoadingSpinner = false;
      });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      
      localStorage.removeItem('userid');
      localStorage.removeItem('company_id');

      const company_id = this.loginForm.value.company_name;
      const user_id = this.loginForm.value.user_id;
      const password = this.loginForm.value.password;
      const rememberMe = this.loginForm.value.rememberMe;
      this.showLoader = true;
      let uniqueId = Date.now();

      // remember me functionality
      if (rememberMe) {  
        localStorage.setItem('userid', user_id);
        localStorage.setItem('company_id', company_id);
      }
      
      this._loginService.login(company_id, user_id, password, uniqueId).subscribe(result => {
        if (result['response'].loginsuccess === true) {

          let loggedData: any = {
            'companyid': company_id,
            'username': user_id,
            'deviceid': uniqueId,
            'token': result['response'].token
          }
          this.getUserProfile(loggedData);
        } else {
          this.showLoader = false;
          this._notifyService.showError("Login failed! Please check your credentails", "Error")
        }
        
      },
        error => {
          if (error.status) {
            this._resetForm();
            this._errorMessage = "Something went wrong on server.";
            this._notifyService.showError(this._errorMessage, "Error")
          }
          this.showLoader = false;
        }
      );
    } else {
      this.validateAllFormFields(this.loginForm);
    }
  }

  getUserProfile(loggedUser:any) {
    const postData = {
      request: {
        companyid: loggedUser.companyid,
        userid: loggedUser.username,
        token: loggedUser.token,
        deviceid: loggedUser.deviceid
      }
    };

    this._profileService.getProfile(postData).subscribe((res: any) => {
      if (res.response.errormessage === 'Success' || res.response.errormessage === '') {
        let userDetail = res.response;
        const loggedInData = {
            companyid: loggedUser.companyid,
            userid: loggedUser.username,
            username: this.loginForm.value.user_id,
            token: loggedUser.token,
            deviceid: loggedUser.deviceid,
            firstname: userDetail.firstname,
            emailid: userDetail.emailid
          };
        this._loadingService.showLoadingSpinner = false; 
        localStorage.removeItem('logged-data');
        localStorage.setItem('logged-data', JSON.stringify(loggedInData));
        localStorage.setItem('first-name', userDetail.firstname);
        localStorage.removeItem('frontend-token');
        localStorage.setItem('frontend-token', loggedUser.token);
        this.setUpSystemData();
        this.showLoader = false;
        this._router.navigate(['dashboard']);
      }
    });
  }

  private _resetForm(): void {
    // this.loginForm.reset();
    this.loginForm = this.formBuilder.group({
      company_name: [this.companies[0].companyCode, [Validators.required]],
      user_id: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onLanguageChange() {
    this.lang_id = this.languageForm.value.language_name;
    this.setUpSystemData();
  }

}
