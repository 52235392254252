import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {DataService} from '../../services/data.service';
import {LoginService} from '../../../login/login.service';
import {NotificationService} from '../../../toaster/notification.service';
import {LoadingService} from '../../services/loading.service';
import {UserService} from '../../../users/user.service';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.css']
})
export class AddEditUserComponent implements OnInit, AfterViewChecked {
  type: 'create' | 'edit' = 'create';
  form: FormGroup;
  submitted = false;
  data: any;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  functionCtrl = new FormControl();
  loggedUser;
  activeBtnChecked = false;
  departmentList = [];
  pattern = {
    // phone: '^[0][1-9]\\d{9}$|^[1-9]\\d{9}$'
    phone: '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$'
  };
  literals;
  activeCheckBoxElement:string;
  @ViewChild('functionsInput') functionsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private dialogRef: MatDialogRef<AddEditUserComponent>,
              @Inject(MAT_DIALOG_DATA) dialogData, private fb: FormBuilder,
              private userService: UserService, private dataService: DataService,
              private loginService: LoginService, private notifyService: NotificationService,
              private loadingService: LoadingService, private cd: ChangeDetectorRef) {
    this.loggedUser = this.dataService.getLoggedUser();
    this.data = dialogData;
    if (this.data) {
      this.type = 'edit';
    }
    this.getDepartments();
  }

  ngOnInit(): void {
    this.literals = this.loginService.getLiterals();
    this.form = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      emailid: ['', [Validators.required, Validators.email]],
      active: ['no', Validators.required],
      deptid: ['', Validators.required],
      contact: ['', Validators.required],
    });
    this.activeCheckBoxElement = ".custom-control.custom-switch .custom-control-label .activeInactive";
    setTimeout(() => {
      document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblInActive);
    }, 200);

    if (this.type === 'edit') {
      this.form = this.fb.group({
        firstname: [this.data.First_name, Validators.required],
        lastname: [this.data.last_name, Validators.required],
        emailid: [this.data.Erp_id],
        active: [this.data.user_active, Validators.required],
        deptid: ['', Validators.required],
        contact: [this.data['contact#'], Validators.required]
      });
      this.activeBtnChecked = (this.data.user_active === 'yes');

      setTimeout(() => {
        if (this.activeBtnChecked) {
          document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblActive);
        } else {
          document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblInActive);
        }
      }, 200);
    }
  }

  ngAfterViewChecked() {
    this.literals = this.loginService.getLiterals();
    this.cd.detectChanges();
  }

  /**
   * function used for get department list
   */
  getDepartments() {
    const postData = {
      request: {
        companyid: this.loggedUser.companyid,
        token: this.loginService.isLoggedInToken(),
        deviceid: this.loggedUser.deviceid,
      }
    };
    this.userService.getDepartmentsList(postData).subscribe((res: any) => {
      this.loadingService.showLoadingSpinner = false;
      this.departmentList = res.response.dept.ttDept;
      if (this.type === 'edit') {
        const selectedDepartment = this.departmentList.find(x => x.dept_name === this.data.department);
        this.form.get('deptid').setValue(selectedDepartment.Dept_id);
      }
    });
  }

  /**
   * function user for toggle active status
   * @param checkbox = checkbox ele
   */
  setActive(checkbox) {

    if (checkbox.checked) {
      document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblActive);
    } else {
      document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblInActive);
    }

    if (checkbox.checked) {
      this.form.get('active').setValue('yes');
    } else {
      this.form.get('active').setValue('no');
    }
  }

  /**
   * function used to create group
   */
  createUser() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    } else {
      this.loadingService.showLoadingSpinner = true;
      const postData = {
        request: {
          companyid: this.loggedUser.companyid,
          token: this.loginService.isLoggedInToken(),
          deviceid: this.loggedUser.deviceid,
          firstname: this.form.value.firstname,
          lastname: this.form.value.lastname,
          emailid: this.form.value.emailid,
          contact: this.form.value.contact,
          active: this.form.value.active,
          deptid: this.form.value.deptid
        }
      };
      this.userService.addUser(postData).subscribe((res: any) => {
        this.loadingService.showLoadingSpinner = false;
        if (res.response.statuscode === 200) {
          this.notifyService.showSuccess(res.response.errormessage, 'Success');
          this.dialogRef.close({
            created: true
          });
        } else if (res.response.errormessage === 'Invalid Session') {
          this.dataService.tokenExpired();
        } else {
          this.notifyService.showError(res.response.errormessage, 'Error');
        }
      }, error => {
        this.loadingService.showLoadingSpinner = false;
        this.notifyService.showError(error.message, 'Error');
      });
    }
  }

  /**
   * function used to edit group
   */
  editUser() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    } else {
      this.loadingService.showLoadingSpinner = true;
      const postData = {
        request: {
          companyid: this.loggedUser.companyid,
          token: this.loginService.isLoggedInToken(),
          deviceid: this.loggedUser.deviceid,
          uid: this.data.uid,
          firstname: this.form.value.firstname,
          lastname: this.form.value.lastname,
          contact: this.form.value.contact,
          active: this.form.value.active,
          deptid: this.form.value.deptid
        }
      };
      this.userService.editUser(postData).subscribe((res: any) => {
        this.loadingService.showLoadingSpinner = false;
        if (res.response.statuscode === 200) {
          this.notifyService.showSuccess(res.response.errormessage, 'Success');
          this.dialogRef.close({
            updated: true
          });
        } else if (res.response.errormessage === 'Invalid Session') {
          this.dataService.tokenExpired();
        } else {
          this.notifyService.showError(res.response.errormessage, 'Error');
        }
      }, error => {
        this.loadingService.showLoadingSpinner = false;
        this.notifyService.showError(error.message, 'Error');
      });
    }
  }

  /**
   * function used when submit form
   */
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    } else {
      if (this.type === 'edit') {
        this.editUser();
      } else {
        this.createUser();
      }
    }
  }

  omit_special_char(event)
  {   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 ); 
  }
  /**
   * function used when cancel form
   */
  cancel(){
    this.dialogRef.close({
      cancel: true
    });
  }

}
