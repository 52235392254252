<div class="dashboard-wrap h-100">
  
  <app-sidebar #sidebar></app-sidebar>

  <div class="dashboard-navigation-bg"></div>

  <div class="dashboard-content-wrap">

      <header class="page-header">
          <div class="row">
              <div class="col-md-8">
                  <h1>{{literals.LblEditProfile}}</h1>
                  <h3>You can edit your profile here</h3>
              </div>
              <div class="col-md-4 d-flex justify-content-end logged-in-user">

                  <div class="mobile-logo">
                      <a href="javascript:void(0)" class="open-mobile-menu mr-2">
                          <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0 12H18V9.98438H0V12ZM0 5.01562V6.98438H13V5.01562H0ZM0 0V2.01562H18V0H0Z" fill="#555555"/>
                          </svg>
                      </a>                                
                      <img width="110" src="../../assets/images/Logo.svg" alt="RPM">
                  </div>
                  
                  <app-header></app-header>

              </div>
          </div>
      </header>


      <form [formGroup]="form"  (ngSubmit)="editProfile()">
          <div class="row d-flex">
              <div class="col-md-6">
                  <div class="form-group">
                      <label class="main-label control-label required-after-login">{{literals.LblFirstName}}</label>
                      <input type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('firstname').errors }" required/>
                      <div *ngIf="submitted && form.get('firstname').errors" class="invalid-feedback">
                          <div *ngIf="form.get('firstname').errors.required">{{literals.LblFirstNameError}}</div>
                      </div>
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="form-group">
                      <label class="main-label control-label required-after-login">{{literals.LblLastName}}</label>
                      <input type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('lastname').errors }" required/>
                      <div *ngIf="submitted && form.get('lastname').errors" class="invalid-feedback">
                          <div *ngIf="form.get('lastname').errors.required">{{literals.LblLastNameError}}</div>
                      </div>
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="form-group">
                      <label class="main-label control-label required-after-login">{{literals.LblContact}}</label>
                      <input type="tel" formControlName="contact"  class="form-control" placeholder="+1-9876-5432-01" [pattern]="pattern.phone" [ngClass]="{ 'is-invalid': submitted && form.get('contact').errors }" required/>
                      <div *ngIf="submitted && form.get('contact').errors" class="invalid-feedback">
                          <div *ngIf="form.get('contact').errors.required">{{literals.LblContactRequired}}</div>
                          <div *ngIf="form.get('contact').errors.pattern">{{literals.LblContactInvalid}}</div>
                      </div>
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="form-group">
                      <label class="main-label" [ngClass]="{'control-label required-after-login': userDetail.emailid === '' }">{{literals.LblEmail}}</label>
                      <input type="email" formControlName="emailid" class="form-control" placeholder="example@ymail.com" [ngClass]="{ 'is-invalid': submitted && form.get('emailid').errors }" [required]="userDetail.emailid === ''" >
                      <div *ngIf="submitted && form.get('emailid').errors" class="invalid-feedback">
                          <div *ngIf="form.get('emailid').errors.required">{{literals.LblEmailRequired}}</div>
                          <div *ngIf="form.get('emailid').errors.email">{{literals.LblEmailInvalid}}</div>
                      </div>
                  </div>
              </div>
          </div>

          <!--modal-content action buttons-->
          <div class="modal-actions">
              <button type="submit" class="btn btn-blue btn-radius">{{literals.LblUpdate}}</button>
          </div>
          <!--modal-content action buttons-->
      </form>

  </div>

</div>

