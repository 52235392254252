import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {LocalStoreService} from '../shared-module/services/local-store.service';


@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  langId: any;

  constructor(private _http: HttpClient, private store: LocalStoreService) {
    this.langId = '0';
  }

  public sendOtp (companyid: any, userid: any) {

    const headers = { 'content-type': 'application/json' };
    const data = {
      request: {
        companyid: companyid,
        userid: userid
      }
    };

    const body = JSON.stringify(data);
    return this._http.post(environment.apiUrl + 'cdirpm/rest/cdirpmService/sendotp', body, { headers: headers });
  }

  public verifyotp (otp:string, companyid: any, userid: any) {

    const headers = { 'content-type': 'application/json' };
    const data = {
      request: {
        companyid: companyid,
        userid: userid,
        otp: otp
      }
    };

    const body = JSON.stringify(data);
    return this._http.post(environment.apiUrl + 'cdirpm/rest/cdirpmService/verifyotp', body, { headers: headers });
  }

  public resetpassword (password:string, companyid: any, userid: any) {

    const headers = { 'content-type': 'application/json' };
    const data = {
      request: {
        companyid: companyid,
        userid: userid,
        newpassword: password
      }
    };

    const body = JSON.stringify(data);
    return this._http.post(environment.apiUrl + 'cdirpm/rest/cdirpmService/resetpassword', body, { headers: headers });
  }

}
