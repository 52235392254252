<!--modal-header start-->
<div class="pb-2 d-flex border-bottom align-items-center justify-content-between">
    <h5 class="modal-title mb-0" mat-dialog-title>{{data.name}}</h5>
    <button type="button" class="close" mat-dialog-close>
        <span class="material-icons">highlight_off</span>
    </button>
</div>
<!--modal-header end-->

<!--modal-content body start-->
<mat-dialog-content class="pt-4">

    <div class="chips d-inline-block" *ngFor="let function of data.functions">
        <span class="badge badge-md badge-primary m-1" *ngIf="data.policy">{{function}}</span>
        <span class="badge badge-md badge-primary m-1" *ngIf="!data.policy">{{function.function_name}}</span>
    </div>

    <!--modal-content action buttons-->
<!--    <div class="modal-actions text-center">-->
<!--        <button type="button" class="btn btn-gray btn-radius" mat-dialog-close>Close</button>-->
<!--    </div>-->
    <!--modal-content action buttons-->
</mat-dialog-content>
<!--modal-content body end-->
