import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './headers/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GroupsComponent } from './groups/groups.component';
import { RolesComponent } from './roles/roles.component';
import { UsersComponent } from './users/users.component';
import { AddEditGroupComponent } from './shared-module/components/add-edit-group/add-edit-group.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GroupService } from './groups/group.service';
import { RolesService } from './roles/roles.service';
import { DeleteConfirmationComponent } from './shared-module/components/delete-confirmation/delete-confirmation.component';
import { FieldErrorDisplayComponent } from './field-error-display/field-error-display.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthLoginGuard } from './guards/authLogin.guard';
import { AddEditRoleComponent } from './shared-module/components/add-edit-role/add-edit-role.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ViewAllFunctionsComponent } from './shared-module/components/view-all-functions/view-all-functions.component';
import { ViewAllGroupsComponent } from './shared-module/components/view-all-groups/view-all-groups.component';
import { LoadingSpinnerComponent } from './shared-module/components/loading-spinner/loading-spinner.component';
import {UserService} from './users/user.service';
import { AddEditUserComponent } from './shared-module/components/add-edit-user/add-edit-user.component';
import {EditProfileComponent} from './edit-profile/edit-profile.component';
import {ProfileService} from './edit-profile/profile.service';
import {DashboardService} from './dashboard/dashboard.service';
import {NgCircleProgressModule} from 'ng-circle-progress';
import { PolicyAsignComponent } from './policy-assignment/policy-assign.component';
import { AddEditPolicyComponent } from './shared-module/components/add-edit-policy/add-edit-policy.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

// new branch
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    DashboardComponent,
    GroupsComponent,
    RolesComponent,
    UsersComponent,
    ForgotPasswordComponent,
    FieldErrorDisplayComponent,
    HeaderComponent,
    AddEditGroupComponent,
    DeleteConfirmationComponent,
    AddEditRoleComponent,
    ViewAllFunctionsComponent,
    ViewAllGroupsComponent,
    LoadingSpinnerComponent,
    AddEditUserComponent,
    EditProfileComponent,
    PolicyAsignComponent,
    AddEditPolicyComponent,
    ChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatDialogModule,
    MatButtonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-bottom-right'
    }),
    MatTooltipModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      space: -20,
      outerStrokeWidth: 20,
      innerStrokeWidth: 20,
      innerStrokeColor: '#e7e8ea',
      animateTitle: false,
      animationDuration: 1000,
      showUnits: false,
      showTitle: false,
      showSubtitle: false,
      showBackground: false,
      clockwise: true,
    })
  ],
  providers: [
    AuthGuard,
    AuthLoginGuard,
    GroupService,
    RolesService,
    GroupsComponent,
    LoadingSpinnerComponent,
    UserService, UsersComponent,
    ProfileService, DashboardService, RolesComponent,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {
        disableClose: true,
        hasBackdrop: true
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
