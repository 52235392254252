<div class="dashboard-wrap h-100">
  
    <app-sidebar #sidebar></app-sidebar>
  
    <div class="dashboard-navigation-bg"></div>
  
    <div class="dashboard-content-wrap">
  
        <header class="page-header">
            <div class="row">
                <div class="col-md-8">
                    <h1>{{literals.LblEditProfile}}</h1>
                    <h3>{{literals.LblChangePasswordHere}}</h3>
                </div>
                <div class="col-md-4 d-flex justify-content-end logged-in-user">
  
                    <div class="mobile-logo">
                        <a href="javascript:void(0)" class="open-mobile-menu mr-2">
                            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 12H18V9.98438H0V12ZM0 5.01562V6.98438H13V5.01562H0ZM0 0V2.01562H18V0H0Z" fill="#555555"/>
                            </svg>
                        </a>                                
                        <img width="110" src="../../assets/images/Logo.svg" alt="RPM">
                    </div>
                    
                    <app-header></app-header>
  
                </div>
            </div>
        </header>
  
  
        <form [formGroup]="form"  (ngSubmit)="editProfile()">
            <div class="row d-flex">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="main-label control-label required-after-login">{{literals.LblNewPassword}}</label>
                        <input type="password" placeholder="{{literals.LblNewPassword}}" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('password').errors }" required/>
                        <div *ngIf="submitted && form.get('password').errors" class="invalid-feedback">
                            <div *ngIf="form.get('password').errors.required">{{literals.LblProvidePassword}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="main-label control-label required-after-login">{{literals.LblConfirmPassword}}</label>
                        <input type="password" placeholder="{{literals.LblConfirmPassword}}" formControlName="c_password" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.get('c_password').errors }" required/>
                        <div *ngIf="submitted && form.get('c_password').errors" class="invalid-feedback">
                            <div *ngIf="form.get('c_password').errors.required">{{literals.LblProvideConfirmPassword}}</div>
                        </div>
                        <app-field-error-display [displayError]="form.hasError('notSame')"
                            errorMsg="{{literals.LblPasswordMismatch}}">
                        </app-field-error-display> 
                    </div>
                </div>
            </div>
  
            <!--modal-content action buttons-->
            <div class="modal-actions">
                <button type="submit" class="btn btn-blue btn-radius">{{literals.LblChangePassword}}</button>
            </div>
            <!--modal-content action buttons-->
        </form>
  
    </div>
  
  </div>
  
  