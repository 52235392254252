import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStoreService } from './local-store.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/toaster/notification.service';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    private errorData: { errorDesc: string; errorTitle: string };

    constructor(public http: HttpClient,
        private store: LocalStoreService,
        private _router: Router,
        private _notifyService: NotificationService
    ) { }

    public getData<T>(url: string) {
        return this.http.get(url).pipe(
            catchError(this.handleError)
        );
    }

    public getDataWithHeader<T>(url: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
            })
        };
        return this.http.get(url, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public postDataWithHeader<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public postDataWithoutHeader<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                platform: 'Web'
            })
        };
        return this.http.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public postData<T>(url: string, toAdd: any) {
        return this.http.post(url, toAdd).pipe(
            catchError(this.handleError)
        );
    }

    public getToken() {
        return localStorage.getItem('frontend-token');
    }

    public getLoggedUser() {
        return this.store.getItem('logged-data');
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }

        this.errorData = {
            errorTitle: 'Oops! Request for document failed',
            errorDesc: 'Something bad happened. Please try again later.'
        };
        return throwError(this.errorData);
    }

    public tokenExpired() {
        this._notifyService.showError('Token expired, please login!', "Error")
        localStorage.removeItem('frontend-token');
        this._router.navigate(['login']);
    }
}
