import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DataService} from '../shared-module/services/data.service';
import {LoginService} from '../login/login.service';
import {NotificationService} from '../toaster/notification.service';
import {LoadingService} from '../shared-module/services/loading.service';
import {ProfileService} from './profile.service';
import {AddEditUserComponent} from '../shared-module/components/add-edit-user/add-edit-user.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {UserService} from '../users/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit, AfterViewChecked {
  type: 'create' | 'edit' = 'edit';
  form: FormGroup;
  submitted = false;
  data: any;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  functionCtrl = new FormControl();
  loggedUser;
  userDetail: any;
  pattern = {
    phone: '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$'
  };
  literals;

  constructor(private fb: FormBuilder, private dataService: DataService,
              private loginService: LoginService, private notifyService: NotificationService,
              private loadingService: LoadingService, private profileService: ProfileService,
              private cd: ChangeDetectorRef) {
    this.loggedUser = this.dataService.getLoggedUser();
    this.getProfile();
    console.log(this.loginService.getLiterals());
  }

  ngOnInit(): void {
    this.literals = this.loginService.getLiterals();
    this.form = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      emailid: ['', [Validators.email]],
      contact: ['', Validators.required],
    });
  }

  ngAfterViewChecked() {
    this.literals = this.loginService.getLiterals();
    this.cd.detectChanges();
  }

  /**
   * function used for get profile
   */
  getProfile() {
    this.loadingService.showLoadingSpinner = true;
    const postData = {
      request: {
        companyid: this.loggedUser.companyid,
        userid: this.loggedUser.username,
        token: this.loginService.isLoggedInToken(),
        deviceid: this.loggedUser.deviceid,
      }
    };
    this.profileService.getProfile(postData).subscribe((res: any) => {
      if (res.response.errormessage === 'Success' || res.response.errormessage === '') {
        this.userDetail = res.response;
        this.loadingService.showLoadingSpinner = false;
        this.form = this.fb.group({
          firstname: ['' || this.userDetail.firstname, Validators.required],
          lastname: ['' || this.userDetail.lastname, Validators.required],
          emailid: ['' || this.userDetail.emailid, [Validators.email]],
          contact: ['' || this.userDetail.contact, Validators.required],
        });
      }  else if (res.response.errormessage === 'Invalid Session') {
        this.dataService.tokenExpired();
      }
    });
  }

  /**
   * function used to edit profile
   */
  editProfile() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    } else {
      this.loadingService.showLoadingSpinner = true;
      const postData = {
        request: {
          companyid: this.loggedUser.companyid,
          token: this.loginService.isLoggedInToken(),
          langid: this.loginService.getSystemSetupData().languageId,
          deviceid: this.loggedUser.deviceid,
          userid: this.loggedUser.username,
          firstname: this.form.value.firstname,
          lastname: this.form.value.lastname,
          emailid: this.form.value.emailid,
          contact: this.form.value.contact,
        }
      };
      
      this.profileService.editProfile(postData).subscribe((res: any) => {
        this.loadingService.showLoadingSpinner = false;
        if (res.response.statuscode === 200) {
          localStorage.setItem("first-name", this.form.value.firstname);
          this.notifyService.showSuccess(res.response.errormessage, 'Success');
          this.getProfile();
        } else {
          this.notifyService.showError(res.response.errormessage, 'Error');
        }
      }, error => {
        this.loadingService.showLoadingSpinner = false;
        this.notifyService.showError(error.message, 'Error');
      });
    }
  }
}
