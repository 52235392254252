import { Injectable } from '@angular/core';
import { DataService } from '../shared-module/services/data.service';
import { environment } from '../../environments/environment';

@Injectable()
export class GroupService {
    configuration = {
        API_ENDPOINT : environment.apiUrl,
    };

    constructor(
        private dataService: DataService ) {
    }

    public getGroupsList(body) {
        return this.dataService.postData(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/getgroups', body
        );
    }
    public addGroup(body) {
        return this.dataService.postData(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/addgroups', body
        );
    }
    public editGroup(body) {
        return this.dataService.postData(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/editgroups', body
        );
    }
    public deleteGroup(body) {
        return this.dataService.postData(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/deletegroups', body
        );
    }
    public addRole(body) {
        return this.dataService.postData(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/addroles', body
        );
    }
    public editRole(body) {
        return this.dataService.postData(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/editroles', body
        );
    }
    public deleteRole(body) {
        return this.dataService.postData(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/deleteroles', body
        );
    } 

}
