<div class="dropdown user-dropdown">
  <button class="btn btn-radius btn-white btn-md btn-block dropdown-toggle" type="button" id="userDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <div class="btn-content"><span class="material-icons">account_circle</span> <span class="user-name text-capitalize">{{loggedIn_first_name}}</span></div>
  </button>
  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
    <a class="dropdown-item" routerLink="/edit-profile"><span class="material-icons">edit</span>
      {{literals.LblEditProfile}}
    </a>
    <a class="dropdown-item" routerLink="/change-password"><span class="material-icons">vpn_key</span>
      {{literals.LblChangePassword}}
    </a>
    <a class="dropdown-item logout-app" (click)="logout()"><span class="material-icons">exit_to_app</span>
      {{literals.LblLogout}}
    </a>

  </div>
</div>
