import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { LoginService } from '../login/login.service';

@Injectable()
export class AuthLoginGuard implements CanActivate, CanActivateChild {
    CurrentSubsObj: any;
    constructor(private _loginService: LoginService, private _router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        
        if (!this._loginService.isLoggedInToken()) {
            return true;
        } else {
            this._router.navigate(['dashboard']);
            return false;
        }
        // this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        // return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

}
