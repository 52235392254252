import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SystemSetupService {

  constructor(private _http: HttpClient) {

  }

  getSystemsetup(lang_id: any) {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json');

    const data = {
      request: {
        langid: lang_id
      }
    };

    const body = JSON.stringify(data);
    return this._http.post(environment.apiUrl + 'cdirpm/rest/cdirpmService/systemsetup', body, {headers});
  }

}
