import { Injectable } from '@angular/core';
import { DataService } from '../shared-module/services/data.service';
import { environment } from '../../environments/environment';

@Injectable()
export class UserService {
    configuration = {
        API_ENDPOINT : environment.apiUrl,
    };

    constructor(
        private dataService: DataService ) {
    }

    public getDepartmentsList(body) {
        return this.dataService.postData(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/getdept', body
        );
    }

    public getUsersList(body) {
        return this.dataService.postData(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/getusers', body
        );
    }
    public addUser(body) {
        return this.dataService.postData(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/addusers', body
        );
    }
    public editUser(body) {
        return this.dataService.postData(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/editusers', body
        );
    }
    public deleteUser(body) {
        return this.dataService.postData(
            this.configuration.API_ENDPOINT + 'cdirpm/rest/cdirpmService/deleteusers', body
        );
    }

}
