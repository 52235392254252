import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GroupsComponent } from './groups/groups.component';
import { UsersComponent } from './users/users.component';
import { RolesComponent } from './roles/roles.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthLoginGuard } from './guards/authLogin.guard';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { PolicyAsignComponent } from './policy-assignment/policy-assign.component';
import { ChangePasswordComponent } from './change-password/change-password.component';


const routes: Routes = [
  { path: 'login',  component: LoginComponent, canActivate: [AuthLoginGuard] },
  { path: '',  component: LoginComponent },
  { path: 'dashboard',  component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'groups',  component: GroupsComponent, canActivate: [AuthGuard] },
  { path: 'roles',  component: RolesComponent, canActivate: [AuthGuard] },
  { path: 'users',  component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password',  component: ForgotPasswordComponent },
  { path: 'edit-profile',  component: EditProfileComponent },
  { path: 'policy-assignment',  component: PolicyAsignComponent },
  { path: 'change-password',  component: ChangePasswordComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
