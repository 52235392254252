import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {GroupService} from '../../../groups/group.service';
import {DataService} from '../../services/data.service';
import {LoginService} from '../../../login/login.service';
import {NotificationService} from '../../../toaster/notification.service';
import { LoadingService } from '../../../shared-module/services/loading.service';

export interface Fields {
  id: number;
  group_name: string;
  active: string;
  roles_group: [];
}

@Component({
  selector: 'app-add-edit-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.css']
})
export class AddEditRoleComponent implements OnInit, AfterViewChecked {
  type: 'create' | 'edit' = 'create';
  form: FormGroup;
  submitted = false;
  data: any;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  groupCtrl = new FormControl();
  filteredGroups: Observable<any[]>;
  groups: any[] = [];
  allGroups: any = [];
  loggedUser;
  activeBtnChecked = false;
  literals;
  activeCheckBoxElement:string;
  @ViewChild('groupsInput') groupsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private dialogRef: MatDialogRef<AddEditRoleComponent>,
              @Inject(MAT_DIALOG_DATA) dialogData, private fb: FormBuilder,
              private adminService: GroupService, private dataService: DataService,
              private loginService: LoginService, private notifyService: NotificationService,
              public _loadingService: LoadingService, private cd: ChangeDetectorRef,
              private groupService: GroupService) {
    this.loggedUser = this.dataService.getLoggedUser();
    this.data = dialogData;
    this.getGroupFunctionsList();
    if (this.data) {
      this.type = 'edit';
      this.groups = this.data.roles_group;
    }

  }

  ngOnInit(): void {
    localStorage.removeItem('allGroups');
    this.literals = this.loginService.getLiterals();
    this.form = this.fb.group({
      group_id: '',
      role_name: ['', Validators.required],
      active: ['no', Validators.required],
      roles_group: [this.groups]
    });
    this.activeCheckBoxElement = ".custom-control.custom-switch .custom-control-label .activeInactive";
    setTimeout(() => {
      document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblInActive);
    }, 500);

    if (this.type === 'edit') {
      console.log(this.data);
      this.form = this.fb.group({
        group_id: [this.data.roles_id],
        role_name: [this.data.roles_name, Validators.required],
        active: [this.data.roles_active, Validators.required],
        roles_group: [this.data.roles_group]
      });
      this.activeBtnChecked = (this.data.roles_active === 'yes');
      setTimeout(() => {
        if (this.activeBtnChecked) {
          document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblActive);
        } else {
          document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblInActive);
        }
      }, 200);
    }
  }

  ngAfterViewChecked() {
    this.literals = this.loginService.getLiterals();
    this.cd.detectChanges();
  }

  /**
   * function used for get group functions list
   */
  getGroupFunctionsList() {
    const postData = {
      request: {
        companyid: this.loggedUser.companyid,
        token: this.loginService.isLoggedInToken(),
        deviceid: this.loggedUser.deviceid,
      }
    };
    this.groupService.getGroupsList(postData).subscribe((res: any) => {
      if (res.response.errormessage === 'Success' || res.response.errormessage === '') {
        let array = [];
        array = res.response.groups.ttgroups;
        this.allGroups = array.filter(x => x.group_active === 'yes');
        let allGroups_t = array.filter(x => x.group_active === 'yes');
        localStorage.setItem('allGroups', JSON.stringify(allGroups_t));

      } else if (res.response.errormessage === 'Invalid Session') {
        this.dataService.tokenExpired();
      }

      this.filteredGroups = this.groupCtrl.valueChanges.pipe(
          startWith(null),
          map((value: string | null) => value ? this._filter(value) : this.allGroups.slice()));
    });
  }

  add(event): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      const index = this.groups.findIndex(x => x === value.trim());
      if (index === -1) {
        this.groups.push(value.trim());
        this.form.get('roles_group').setValue(this.groups);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.groupCtrl.setValue(null);
  }

  remove(group: string): void {
    const index = this.groups.indexOf(group);

    if (index >= 0) {
      this.groups.splice(index, 1);
      this.form.get('roles_group').setValue(this.groups);
    }
    this.addElementToArray(group);
  }

  selected(event): void {
    const index = this.groups.findIndex(x => x.group_id === event.option.value.group_id);
    if (index === -1) {
      this.groups.push(event.option.value);
      this.form.get('roles_group').setValue(this.groups);
    }
    this.groupsInput.nativeElement.value = '';
    this.groupCtrl.setValue(null);
    this.removeElementFromArray(event.option.value);
    const chipElement = document.querySelector('#groupsToRole');
    if (chipElement instanceof HTMLElement) {
      chipElement.blur();
      setTimeout(() => { chipElement.focus() }, 1);
    } 
    
  }

  addElementToArray(group) {
    let allGroups = JSON.parse(localStorage.getItem('allGroups'));
    var index = allGroups.findIndex(function (item, i) {
      return item.group_id === group.group_id
    });
    let index_t = this.allGroups.indexOf(group);
    if (index_t === -1) {
      this.allGroups.splice(index, 0, group);
      this.filteredGroups = this.groupCtrl.valueChanges.pipe(
        startWith(null),
        map((value: string | null) => value ? this._filter(value) : this.allGroups.slice()));
    }
  }

  removeElementFromArray(group) {
    let allGroups = JSON.parse(localStorage.getItem('allGroups'));
    var index = allGroups.findIndex(function (item, i) {
      return item.group_id === group.group_id
    });
    
    if(this.allGroups.length === 1) {
      this.allGroups.splice(0, 1);
    }  else {
      this.allGroups.splice(index, 1);   
    }

    this.filteredGroups = this.groupCtrl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => value ? this._filter(value) : this.allGroups.slice()));
    
  }

  private _filter(value): any[] {
    let filterValue;
    if (value.group_name) {
      filterValue = value.group_name.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }

    return this.allGroups.filter(group => group.group_name.toLowerCase().indexOf(filterValue) === 0);
  }

  setActive(checkbox) {

    if (checkbox.checked) {
      document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblActive);
    } else {
      document.querySelector(this.activeCheckBoxElement).setAttribute('data-text', this.literals.LblInActive);
    }

    if (checkbox.checked) {
      this.form.get('active').setValue('yes');
    } else {
      this.form.get('active').setValue('no');
    }
  }

  /**
   * function used to create group
   */
  createRole() {
    this.submitted = true;
    this._loadingService.showLoadingSpinner = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    } else {
      const postData = {
        request: {
          companyid: this.loggedUser.companyid,
          token: this.loginService.isLoggedInToken(),
          deviceid: this.loggedUser.deviceid,
          rolename: this.form.value.role_name,
          active: this.form.value.active,
          roll_groups: this.form.value.roles_group
        }
      };
      this.adminService.addRole(postData).subscribe((res: any) => {
        if (res.response.statuscode === 200) {
          // dialog will be closed if status will be ok
          this.dialogRef.close({
            created: true
          });
          this.notifyService.showSuccess(res.response.errormessage, 'Success');
        } else if (res.response.errormessage === 'Invalid Session') {
          this.dataService.tokenExpired();
        } else {
          this.notifyService.showError(res.response.errormessage, 'Error');
        }
        this._loadingService.showLoadingSpinner = false;
      }, error => {
        this._loadingService.showLoadingSpinner = false;
        this.notifyService.showError(error.message, 'Error');
      });
    }
  }


  /**
   * function used to edit group
   */
  editRole() {
    this.submitted = true;
    this._loadingService.showLoadingSpinner = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    } else {
      const postData = {
        request: {
          companyid: this.loggedUser.companyid,
          token: this.loginService.isLoggedInToken(),
          deviceid: this.loggedUser.deviceid,
          roleid: this.data.roles_id,
          rolename: this.form.value.role_name,
          active: this.form.value.active,
          roll_groups: this.form.value.roles_group
        }
      };
      this.adminService.editRole(postData).subscribe((res: any) => {
        if (res.response.statuscode === 200) {
          // dialog will be closed if status will be ok
          this.dialogRef.close({
            updated: true
          });
          this.notifyService.showSuccess(res.response.errormessage, 'Success');
        } else if (res.response.errormessage === 'Invalid Session') {
          this.dataService.tokenExpired();
        } else {
          this.notifyService.showError(res.response.errormessage, 'Error');
        }
        this._loadingService.showLoadingSpinner = false;
      }, error => {
        this._loadingService.showLoadingSpinner = false;
        this.notifyService.showError(error.message, 'Error');
      });
    }
  }

  /**
   * function used when submit form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    } else {
      if (this.type === 'edit') {
        this.editRole();
      } else {
        console.log('add');
        this.createRole();
      }
    }
  }

  /**
   * function used when cancel form
   */
  cancel(){
    this.dialogRef.close({
      cancel: true
    });
  }

}
