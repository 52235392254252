import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {GroupService} from '../../../groups/group.service';
import {DataService} from '../../services/data.service';
import {LoginService} from '../../../login/login.service';
import {NotificationService} from '../../../toaster/notification.service';
import { LoadingService } from '../../../shared-module/services/loading.service';
import {RolesService} from '../../../roles/roles.service';
import {PolicyAssignService} from '../../../policy-assignment/policy-assign.service';

export interface Fields {
  id: number;
  group_name: string;
  active: string;
  roles_group: [];
}

@Component({
  selector: 'app-add-edit-policy',
  templateUrl: './add-edit-policy.component.html',
  styleUrls: ['./add-edit-policy.component.css']
})
export class AddEditPolicyComponent implements OnInit, AfterViewChecked {
  type: 'create' | 'edit' = 'create';
  form: FormGroup;
  submitted = false;
  data: any;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  groupCtrl = new FormControl();
  filteredGroups: Observable<any[]>;
  groups: any[] = [];
  allGroups: any = [];
  loggedUser;
  activeBtnChecked = false;
  literals;

  @ViewChild('groupsInput') groupsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private dialogRef: MatDialogRef<AddEditPolicyComponent>,
              @Inject(MAT_DIALOG_DATA) dialogData, private fb: FormBuilder,
              private adminService: GroupService, private dataService: DataService,
              private loginService: LoginService, private notifyService: NotificationService,
              public _loadingService: LoadingService, private groupService: GroupService,
              private cd: ChangeDetectorRef, private roleService: RolesService,
              private _policyAssignService: PolicyAssignService) {
    this.loggedUser = this.dataService.getLoggedUser();
    this.data = dialogData;
    this.getAllRolesList();
    if (this.data) {
      this.type = 'edit';
      console.log(this.data.assigned_roles);
    }

  }

  ngOnInit(): void {
    localStorage.removeItem('allGroups');
    this.literals = this.loginService.getLiterals();
    this.form = this.fb.group({
      user_rolls: [[]],
    });

    if (this.type === 'edit') {
      this.form = this.fb.group({
        user_rolls: [[]],
      });
    }
  }

  ngAfterViewChecked() {
    this.literals = this.loginService.getLiterals();
    this.cd.detectChanges();
  }

  add(event): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      const index = this.groups.findIndex(x => x === value.trim());
      if (index === -1) {
        this.groups.push(value.trim());
        this.form.get('user_rolls').setValue(this.groups);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.groupCtrl.setValue(null);
  }

  remove(group): void {
    const index = this.groups.indexOf(group);
    this.addElementToArray(group);
    if (index >= 0) {
      this.groups.splice(index, 1);
      this.form.get('user_rolls').setValue(this.groups);
    }
  }

  selected(event): void {
    const index = this.groups.findIndex(x => x.Roles_id === event.option.value.Roles_id);
    if (index === -1) {
      this.groups.push(event.option.value);
      this.form.get('user_rolls').setValue(this.groups);
    }
    console.log(this.groups);
    this.groupsInput.nativeElement.value = '';
    this.groupCtrl.setValue(null);

    this.removeElementFromArray(event.option.value);
    const chipElement = document.querySelector('#rolesTousers');
    if (chipElement instanceof HTMLElement) {
      chipElement.blur();
      setTimeout(() => { chipElement.focus() }, 1);
    } 
    
  }

  addElementToArray(group) {
    let allGroups = JSON.parse(localStorage.getItem('allGroups'));
    var index = allGroups.findIndex(function (item, i) {
      return item.function_id === group.function_id
    });
    let index_t = this.allGroups.indexOf(group);
    if(index_t === -1) {
      this.allGroups.splice(index, 0, group);
      this.filteredGroups = this.groupCtrl.valueChanges.pipe(
        startWith(null),
        map((value: string | null) => value ? this._filter(value) : this.allGroups.slice()));
    }
  }

  removeElementFromArray(group) {
    let allGroups = JSON.parse(localStorage.getItem('allGroups'));
    var index = allGroups.findIndex(function (item, i) {
      return item.function_id === group.function_id
    });

    if(this.allGroups.length === 1) {
      this.allGroups.splice(0, 1);
    }  else {
      this.allGroups.splice(index, 1);  
    }
    this.filteredGroups = this.groupCtrl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => value ? this._filter(value) : this.allGroups.slice()));
  }

  private _filter(value): any[] {
    let filterValue;
    if (value.roles_name) {
      filterValue = value.roles_name.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }

    return this.allGroups.filter(group => group.roles_name.toLowerCase().indexOf(filterValue) === 0);
  }


  /**
   * function used to get all roles list
   */
  getAllRolesList() {
    this._loadingService.showLoadingSpinner = true;
    const postData = {
      request: {
        companyid: this.loggedUser.companyid,
        token: this.loginService.isLoggedInToken(),
        deviceid: this.loggedUser.deviceid
      }
    };
    this.roleService.getRoles(postData).subscribe((res: any) => {
      if (res.response.errormessage === 'Success' || res.response.errormessage === '') {
        let array = [];
        array = res.response.roles.ttRoles;
        this.allGroups = array.filter(x => x.Roles_Active === 'yes');
        console.log(this.allGroups);

        if (this.data) {
          this.groups = [];
          this.data.assigned_roles.forEach(item => {
            const filtered = this.allGroups.find(x => x.roles_name === item);
            this.groups.push(filtered);
            console.log(this.groups);
          });
        }

        localStorage.setItem('allGroups', JSON.stringify(this.allGroups));

      } else if (res.response.errormessage === 'Invalid Session') {
        this.dataService.tokenExpired();
      }
      this._loadingService.showLoadingSpinner = false;
      this.cd.detectChanges();

      this.filteredGroups = this.groupCtrl.valueChanges.pipe(
          startWith(null),
          map((value: string | null) => value ? this._filter(value) : this.allGroups.slice()));
    });
  }

  /**
   * function used to create group
   */
  onSubmit() {
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    } else {
      this._loadingService.showLoadingSpinner = true;
      this.submitted = true;
      const userRolesArray = [];
      this.form.value.user_rolls.forEach(item => {
        userRolesArray.push({
          role_id: item.Roles_id
        });
      });
      const postData = {
        request: {
          companyid: this.loggedUser.companyid,
          token: this.loginService.isLoggedInToken(),
          deviceid: this.loggedUser.deviceid,
          userid: this.data.uid,
          user_rolls: userRolesArray
        }
      };
      this._policyAssignService.assignPolicy(postData).subscribe((res: any) => {
        if (res.response.statuscode === 200) {
          this.dialogRef.close({
            updated: true
          });
          this.notifyService.showSuccess(res.response.errormessage, 'Success');
        } else if (res.response.errormessage === 'Invalid Session') {
          this.dataService.tokenExpired();
        }
        this._loadingService.showLoadingSpinner = false;
      }, error => {
        this._loadingService.showLoadingSpinner = false;
        this.notifyService.showError(error.message, 'Error');
      });
    }
  }

  /**
   * function used when cancel form
   */
  cancel(){
    this.dialogRef.close({
      cancel: true
    });
  }

}
