import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {LoginService} from '../login/login.service';
import {DataService} from '../shared-module/services/data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewChecked {
  literals: any;
  loggedUser;
  loggedIn_first_name:any
  constructor(private _router: Router, private loginService: LoginService,
              private cd: ChangeDetectorRef, private dataService: DataService) { }

  ngOnInit(): void {
    this.loggedUser = this.dataService.getLoggedUser(); 
    this.literals = this.loginService.getLiterals();
  }

  ngAfterViewChecked() {
    this.literals = this.loginService.getLiterals();
    this.loggedIn_first_name = localStorage.getItem("first-name");
    this.cd.detectChanges();
  }

  logout() {
    localStorage.removeItem('frontend-token');
    this._router.navigate(['login']);
  }

}
