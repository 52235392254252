import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog} from '@angular/material/dialog';
import {DataService} from '../shared-module/services/data.service';
import {LoginService} from '../login/login.service';
import {NotificationService} from '../toaster/notification.service';
import {LoadingService} from '../shared-module/services/loading.service';
import {DeleteConfirmationComponent} from '../shared-module/components/delete-confirmation/delete-confirmation.component';
import {Owner} from '../groups/groups.component';
import {PolicyAssignService} from './policy-assign.service';
import {AddEditPolicyComponent} from '../shared-module/components/add-edit-policy/add-edit-policy.component';
import { GroupService } from '../groups/group.service';
import {ViewAllFunctionsComponent} from '../shared-module/components/view-all-functions/view-all-functions.component';

@Component({
  selector: 'app-users',
  templateUrl: './policy-assign.component.html',
  styleUrls: ['./policy-assign.component.css']
})
export class PolicyAsignComponent implements OnInit, AfterViewInit, AfterViewChecked {
  data: Owner[] = [];
  public displayedColumns = ['checkbox', 'id', 'Erp_id', 'First_name', 'last_name', 'role_name' , 'action'];
  public dataSource = new MatTableDataSource<Owner>(this.data);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selection = new SelectionModel<Owner>(true, []);
  loggedUser;
  users = [];
  groups = [];
  isListEmpty = false;
  literals;

  constructor(private dialog: MatDialog, private _policyAssignService: PolicyAssignService,
              private dataService: DataService,
              private cd: ChangeDetectorRef, private notifyService: NotificationService,
              private _loginService: LoginService,
              private _groupService: GroupService,
              public loadingService: LoadingService) {
    this.loggedUser = this.dataService.getLoggedUser();
  }

  ngOnInit(): void {
    this.getAllUsersList();
    this.literals = this._loginService.getLiterals();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewChecked() {
    this.literals = this._loginService.getLiterals();
    this.cd.detectChanges();
  }

  /**
   * function used for open add user dialog
   */
  public openAddDialog() {
    this.dialog.open(AddEditPolicyComponent).afterClosed().subscribe(result => {
      if (result != "" && result.created) {
        setTimeout(() => {
          this.getAllUsersList();
        }, 500);
      }
    });
  }

  /**
   * function used for open delete user dialog
   */
  openDeleteDialog(element) {
    this.dialog.open(DeleteConfirmationComponent).afterClosed().subscribe(result => {
      if (result.delete) {
        this.deleteUser(element);
      }
    });
  }

  /**
   * function used for open edit user dialog
   */
  openEditModal(element) {
    this.dialog.open(AddEditPolicyComponent, {
      data: element
    }).afterClosed().subscribe(result => {
      if (result.updated || result.cancel) {
        this.cd.detectChanges();
        setTimeout(() => {
          this.getAllUsersList();
        }, 500);
      }
    });
  }

  /**
   * function used to get all users list
   */
  getAllUsersList() {
    this.loadingService.showLoadingSpinner = true;
    this.isListEmpty = false;
    const postData = {
      request: {
        companyid: this.loggedUser.companyid,
        token: this._loginService.isLoggedInToken(),
        deviceid: this.loggedUser.deviceid
      }
    };
    this._policyAssignService.getUsersList(postData).subscribe((res: any) => {
      if (res.response.errormessage === 'Success' || res.response.errormessage === '') {
        this.users = res.response.users.ttusers;
        if (this.users.length > 0) {
          this.isListEmpty = false;
          this.users.map((item) => {
            let array = [];
            let filtered = [];
            array = item.role_name.split(',');
            filtered = array.filter(value => Object.keys(value).length !== 0);
            Object.assign(item, {
              assigned_roles: filtered
            });
          });
          this.users.sort((a, b) => b.uid < a.uid ? -1 : b.uid > a.uid ? 1 : 0);
          this.dataSource.data = this.users;
          this.dataSource.filterPredicate = function(data:any, filter: string): boolean {
            return data.First_name.toLowerCase().includes(filter) || data.last_name.toLowerCase().includes(filter);
          };
        } else {
          this.isListEmpty = true;
        }
      } else if (res.response.errormessage === 'Invalid Session') {
        this.dataService.tokenExpired();
      }

      this.loadingService.showLoadingSpinner = false;
      this.cd.detectChanges();
    });
  }

  /**
   * function used to delete existing user
   */
  deleteUser(element) {
    this.loadingService.showLoadingSpinner = true;
    const postData = {
      request: {
        companyid: this.loggedUser.companyid,
        uid: element.uid,
        token: this._loginService.isLoggedInToken(),
        deviceid: this.loggedUser.deviceid
      }
    };
    this._policyAssignService.deleteUser(postData).subscribe((res: any) => {
      this.loadingService.showLoadingSpinner = false;
      if (res.response.statuscode === 200) {
        this.notifyService.showSuccess(res.response.errormessage, 'Success');
        setTimeout(() => {
          this.getAllUsersList();
        }, 500);
      } else {
        this.notifyService.showError(res.response.errormessage, 'Error');
      }
    }, error => {
      this.notifyService.showError(error.message, 'Error');
      this.loadingService.showLoadingSpinner = false;
    });
  }


  /**
   * function used for filter data form table on input keyup
   * @param value = input value
   */
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }


  /**
   * function used for view all roles
   */
  openAllFunctionsModal(element) {
    this.dialog.open(ViewAllFunctionsComponent, {
      data: {
        name: element.First_name,
        functions: element.assigned_roles,
        policy: true
      }
    });
  }
}
