import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {GroupsComponent} from '../groups/groups.component';
import {UsersComponent} from '../users/users.component';
import {LoginService} from '../login/login.service';
import {RolesComponent} from '../roles/roles.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, AfterViewChecked {
  literals: any;

  constructor(private groupComp: GroupsComponent, private userComp: UsersComponent,
              private loginService: LoginService, private cd: ChangeDetectorRef,
              private rolesComp: RolesComponent) {
  }

  ngOnInit(): void {
    this.literals = this.loginService.getLiterals();
  }

  ngAfterViewChecked() {
    this.literals = this.loginService.getLiterals();
    this.cd.detectChanges();
  }

  openAddGroupModal() {
    this.groupComp.openAddDialog();
  }
  openAddUserModal() {
    this.userComp.openAddDialog();
  }
  openAddRolesModal() {
    this.rolesComp.openAddDialog();
  }

}
