<div class="dashboard-wrap h-100">
        
  <app-sidebar #sidebar></app-sidebar>

  <div class="dashboard-navigation-bg"></div>

  <div class="dashboard-content-wrap">

      <header class="page-header">
          <div class="row">
              <div class="col-md-8">
                    <h1>{{literals.LblRoles}}</h1>
                    <h3>{{literals.LblviewAddEditRoles}}</h3>
              </div>
              <div class="col-md-4 d-flex justify-content-end logged-in-user">
                  <div class="mobile-logo">
                      <a href="javascript:void(0)" class="open-mobile-menu mr-2">
                          <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0 12H18V9.98438H0V12ZM0 5.01562V6.98438H13V5.01562H0ZM0 0V2.01562H18V0H0Z" fill="#555555"/>
                          </svg>
                      </a>                                
                      <img width="110" src="../../assets/images/Logo.svg" alt="RPM">
                  </div>
                  <app-header></app-header>
              </div>
          </div>
      </header>

      <div class="group-search-wrap my-4">
          <div class="row">
              <div class="col-md-6">
                  <div class="input-group">
                      <input #ele type="text" (keyup)="doFilter(ele.value)" class="form-control right" placeholder="{{literals.LblSearchRoles}}">
                      <div class="input-group-append">
                          <span class="input-group-text"><span class="material-icons">search</span></span>
                      </div>
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="d-flex justify-content-end">
                     <a href="javascript:void(0)" (click)="openAddDialog()" class="btn btn-blue btn-md btn-radius btn-icon">{{literals.LblAddNewRole}} <i class="material-icons icon mr-0 ml-2">add_circle</i></a>
                  </div>
              </div>
          </div>
      </div>
      
      <div class="mat-elevation-z8" *ngIf="!isListEmpty">
          <table  class="w-100" mat-table [dataSource]="dataSource" matSort matSortStart="desc">
              <!-- Checkbox Column -->
              <ng-container matColumnDef="checkbox">
                  <th mat-header-cell *matHeaderCellDef>
                      <!-- <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                      </mat-checkbox> -->
                  </th>
                  <td mat-cell *matCellDef="let row">
                      <!-- <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)">
                      </mat-checkbox> -->
                  </td>
              </ng-container>

              <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                  <td mat-cell *matCellDef="let element; let i = index">{{dataSource.filteredData.indexOf(element) + 1}}</td>
              </ng-container>

              <ng-container matColumnDef="roles_name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{literals.LblRoleName}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.roles_name}} </td>
              </ng-container>

              <ng-container matColumnDef="roles_group">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{literals.LblGroupAccess}}</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="chips d-inline-block" *ngFor="let group of element.roles_group; let item = index">
                            <span class="badge badge-md badge-primary p-1" *ngIf="item < 2">{{group.group_name}}</span>
                        </div>
                        <div class="chips" *ngIf="element.roles_group && element.roles_group.length == 0">
                            <span class="badge badge-md badge-dark">N/A</span>
                        </div>
                        <a href="javascript:void(0)" (click)="openAllGroupsModal(element)" class="ml-2" matTooltip="Click to view all groups" *ngIf="element.roles_group && element.roles_group.length > 2">+ {{element.roles_group.length - 2}} {{literals.Lblmore}}</a>
                    </td>
              </ng-container>
              <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{literals.LblStatus}}</th>
                    <td mat-cell *matCellDef="let element" > 
                        <span *ngIf="element.roles_active === 'no'" class="inactive-status">{{literals.LblInActive}}</span>
                        <span *ngIf="element.roles_active === 'yes'" class="active-status">{{literals.LblActive}}</span>
                    </td>
                </ng-container>

              <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{literals.LblAction}}</th>
                  <td mat-cell *matCellDef="let element">
                      <div class="table-actions">
                          <button mat-icon-button (click)="openEditModal(element)"><span class="material-icons">edit</span></button>
                          <button mat-icon-button (click)="openDeleteDialog(element)"><span class="material-icons">delete</span></button>
                      </div>
                  </td>
              </ng-container>
              
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>



      <div *ngIf="isListEmpty" class="no-data-found-box text-muted py-5 d-flex align-items-center justify-content-center">
            {{literals.LblNoRollsAvailable}}
      </div>
  </div>
</div>
