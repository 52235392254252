import {AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AddEditGroupComponent} from '../shared-module/components/add-edit-group/add-edit-group.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {GroupService} from './group.service';
import {DeleteConfirmationComponent} from '../shared-module/components/delete-confirmation/delete-confirmation.component';
import {DataService} from '../shared-module/services/data.service';
import {LoginService} from '../login/login.service';
import {NotificationService} from '../toaster/notification.service';
import {ViewAllFunctionsComponent} from '../shared-module/components/view-all-functions/view-all-functions.component';
import {LoadingService} from '../shared-module/services/loading.service';


export interface Owner{
  group_id: number;
  group_name: string;
  group_active: string;
  group_functions: [];
}

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  data: Owner[] = [];
  public displayedColumns = ['checkbox', 'group_id', 'group_name', 'group_functions','active', 'action'];
  public dataSource = new MatTableDataSource<Owner>(this.data);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selection = new SelectionModel<Owner>(true, []);
  loggedUser;
  functions;
  groupFunctions = [];
  groups = [];
  isListEmpty = false;
  literals: any;

  constructor(private dialog: MatDialog, private groupService: GroupService,
              private dataService: DataService, private loginService: LoginService,
              private cd: ChangeDetectorRef, private notifyService: NotificationService,
              public loadingService: LoadingService) {
    this.loggedUser = this.dataService.getLoggedUser();
  }

  ngOnInit(): void {
    this.getAllGroupsList();
    this.literals = this.loginService.getLiterals();
  }

  ngAfterViewChecked() {
    this.literals = this.loginService.getLiterals();
    this.cd.detectChanges();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  /**
   * function used for open add group dialog
   */
  public openAddDialog() {
    this.dialog.open(AddEditGroupComponent, {
      data: {
        functions: this.functions,
      }
    }).afterClosed().subscribe(result => {
      if (result != "" && result.created) {
        setTimeout(() => {
          this.getAllGroupsList();
        }, 500);
      }
    });
  }

  /**
   * function used for open delete group dialog
   */
  openDeleteDialog(element) {
    this.dialog.open(DeleteConfirmationComponent).afterClosed().subscribe(result => {
      if (result.delete) {
        this.deleteGroup(element);
      }
    });
  }

  /**
   * function used for open edit group dialog
   */
  openEditModal(element) {
    this.dialog.open(AddEditGroupComponent, {
      data: {
        functions: this.functions,
        defaultData: element
      }
    }).afterClosed().subscribe(result => {
      if (result.updated || result.cancel) {
        this.cd.detectChanges();
        setTimeout(() => {
          this.getAllGroupsList();
        }, 500);
      }
    });
  }


  /**
   * function used for open edit group dialog
   */
  openAllFunctionsModal(element) {
    this.dialog.open(ViewAllFunctionsComponent, {
      data: {
        name: element.group_name,
        functions: element.group_functions,
        policy: false
      }
    });
  }

  /**
   * function used to get all groups list
   */
  getAllGroupsList() {
    this.isListEmpty = false;
    this.loadingService.showLoadingSpinner = true;
    const postData = {
      request: {
        companyid: this.loggedUser.companyid,
        token: this.loginService.isLoggedInToken(),
        deviceid: this.loggedUser.deviceid
      }
    };
    this.groupService.getGroupsList(postData).subscribe((res: any) => {
      if (res.response.errormessage === 'Success' || res.response.errormessage === '') {
        this.functions = res.response.functions.tt_functions;
        this.groupFunctions = res.response.group_function.ttgroup_function;
        this.groups = res.response.groups.ttgroups;

        if (this.groups.length > 0) {
          this.isListEmpty = false;
          this.groups.map((item) => {
            const functionToAdd = [];
            this.groupFunctions.map(ele => {
              if (item.group_id === ele.group_id) {
                functionToAdd.push(ele);
                Object.assign(item, {
                  group_functions: functionToAdd
                });
              }
            });
          });
          this.groups.sort((a, b) => a.group_id < b.group_id ? -1 : a.group_id > b.group_id ? 1 : 0);
          this.dataSource.data = this.groups;
        } else {
          this.isListEmpty = true;
        }
      } else if (res.response.errormessage === 'Invalid Session') {
        this.dataService.tokenExpired();
      }

      this.loadingService.showLoadingSpinner = false;
      this.cd.detectChanges();
    });
  }

  /**
   * function used to delete existing group
   */
  deleteGroup(element) {
    this.loadingService.showLoadingSpinner = true;
    const postData = {
      request: {
        companyid: this.loggedUser.companyid,
        groupid: element.group_id,
        token: this.loginService.isLoggedInToken(),
        deviceid: this.loggedUser.deviceid
      }
    };
    this.groupService.deleteGroup(postData).subscribe((res: any) => {
      this.loadingService.showLoadingSpinner = false;
      if (res.response.statuscode === 200) {
        this.notifyService.showSuccess(res.response.errormessage, 'Success');
        setTimeout(() => {
          this.getAllGroupsList();
        }, 500);
      } else {
        this.notifyService.showError(res.response.errormessage, 'Error');
      }
    }, error => {
      this.notifyService.showError(error.message, 'Error');
      this.loadingService.showLoadingSpinner = false;
    });
  }


  /**
   * function used for filter data form table on input keyup
   * @param value = input value
   */
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
