<!--modal-header start-->
<div class="pb-2 d-flex border-bottom align-items-center justify-content-between">
    <h5 class="modal-title mb-0" mat-dialog-title>
        <span *ngIf="type === 'create'">{{literals.LblNewUserName}}</span>
        <span *ngIf="type === 'edit'">Update User</span>
    </h5>
    <button type="button" class="close" (click)="cancel()">
        <span class="material-icons">highlight_off</span>
    </button>
</div>
<!--modal-header end-->

<!--modal-content body start-->
<mat-dialog-content class="py-4">
    <form [formGroup]="form"  (ngSubmit)="onSubmit()">
        <div class="row d-flex">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="main-label control-label required-after-login">{{literals.LblFirstName}}</label>
                    <input type="text" formControlName="firstname" class="form-control" (keypress)="omit_special_char($event)" [ngClass]="{ 'is-invalid': submitted && form.get('firstname').errors }" required/>
                    <div *ngIf="submitted && form.get('firstname').errors" class="invalid-feedback">
                        <div *ngIf="form.get('firstname').errors.required">{{literals.LblFirstNameError}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="main-label control-label required-after-login">{{literals.LblLastName}}</label>
                    <input type="text" formControlName="lastname" class="form-control" (keypress)="omit_special_char($event)" [ngClass]="{ 'is-invalid': submitted && form.get('lastname').errors }" required/>
                    <div *ngIf="submitted && form.get('lastname').errors" class="invalid-feedback">
                        <div *ngIf="form.get('lastname').errors.required">{{literals.LblLastNameError}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="form-group">
                    <label class="main-label control-label required-after-login">{{literals.LblDepartment}}</label>
                    <select formControlName="deptid" class="custom-select" [ngClass]="{ 'is-invalid': submitted && form.get('deptid').errors }" required>
                        <option value="" disabled>Select User Department</option>
                        <option [value]="dept.Dept_id" *ngFor="let dept of departmentList">{{dept.dept_name}}</option>
                    </select>
                    <div *ngIf="submitted && form.get('deptid').errors" class="invalid-feedback">
                        <div *ngIf="form.get('deptid').errors.required">{{literals.LblSelectDepartment}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="custom-control custom-switch mt-4">
                    <input #checkbox (change)="setActive(checkbox)" type="checkbox" class="custom-control-input" id="customSwitch1" [checked]="activeBtnChecked">
                    <label class="custom-control-label" for="customSwitch1">
                        <span class="activeInactive">&nbsp;</span>
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="main-label control-label required-after-login">{{literals.LblContact}}</label>
                    <input type="tel" formControlName="contact"  class="form-control" placeholder="+1-9876-5432-01" [pattern]="pattern.phone" [ngClass]="{ 'is-invalid': submitted && form.get('contact').errors }" required/>
                    <div *ngIf="submitted && form.get('contact').errors" class="invalid-feedback">
                        <div *ngIf="form.get('contact').errors.required">{{literals.LblContactRequired}}</div>
                        <div *ngIf="form.get('contact').errors.pattern">{{literals.LblContactInvalid}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label *ngIf="type === 'create'" class="main-label control-label required-after-login">{{literals.LblEmail}}</label>
                    <label *ngIf="type === 'edit'" class="main-label control-label required-after-login">ERP Id</label>
                    <input type="email" formControlName="emailid" class="form-control" placeholder="example@ymail.com" [ngClass]="{ 'is-invalid': submitted && form.get('emailid').errors }" [required]="type === 'create'" [readonly]="type === 'edit'" [disabled]="type === 'edit'">
                    <div *ngIf="submitted && form.get('emailid').errors" class="invalid-feedback">
                        <div *ngIf="form.get('emailid').errors.required">{{literals.LblEmailIdRequired}}</div>
                        <div *ngIf="form.get('emailid').errors.email">{{literals.LblEmailInvalid}}</div>
                    </div>
                </div>
            </div>
        </div>

        <!--modal-content action buttons-->
        <div class="modal-actions text-center">
            <div class="modal-actions text-center">
                <button *ngIf="type === 'create'" type="submit" class="btn btn-blue btn-radius">{{literals.LblCreate}}</button>
                <button *ngIf="type === 'edit'" type="submit" class="btn btn-blue btn-radius">Update</button>
                <button type="button" (click)="cancel()" class="btn btn-gray btn-radius">{{literals.LblCancel}}</button>
            </div>
        </div>
        <!--modal-content action buttons-->
    </form>
</mat-dialog-content>
<!--modal-content body end-->
