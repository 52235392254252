<aside class="dashboard-sidebar">
      
  <div class="sidebar-content">
      
      <div class="logo-area">
          <img src="../../assets/images/dummy-logo.svg" alt="Logoipsum" [routerLink]="['/dashboard']" >
      </div><!-- .logo-area ends here -->

      <div class="dashboard-navigation">

          <div class="dropdown">
              <button class="btn btn-radius btn-blue btn-md btn-block dropdown-toggle" type="button" id="addNewButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{literals.LblAddNew}}
              </button>
              <div class="dropdown-menu" aria-labelledby="addNewButton">
                <a class="dropdown-item" href="javascript:void(0)" (click)="openAddGroupModal()">{{literals.LblGroup}}</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="openAddRolesModal()">{{literals.LblRoll}}</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="openAddUserModal()">{{literals.LblUser}}</a>
              </div>
          </div>

          <nav class="nav flex-column">
              <a class="nav-link" [routerLink]="['/dashboard']" [routerLinkActive]="['active']"><span class="material-icons">dashboard</span> {{literals.LblDashboard}}</a>
              <a class="nav-link" [routerLink]="['/groups']" [routerLinkActive]="['active']"><span class="material-icons">group</span> {{literals.LblGroups}}</a>
              <a class="nav-link" [routerLink]="['/roles']" [routerLinkActive]="['active']"><span class="material-icons">offline_pin</span> {{literals.LblRoles}}</a>
              <a class="nav-link" [routerLink]="['/users']" [routerLinkActive]="['active']"><span class="material-icons">person</span> {{literals.LblUsers}}</a>
              <a class="nav-link" [routerLink]="['/policy-assignment']" [routerLinkActive]="['active']"><span class="material-icons">assignment</span>{{literals.LblPolicyAssignmnet}}</a>
          </nav>

      </div>                

  </div>

  <div class="powered-by text-center">
      <div>{{literals.LblPoweredBy}}</div>
      <img src="../../assets/images/Logo.svg" alt="RPM" width="100">
  </div>

</aside><!-- .dashboard-sidebar ends here -->
