import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {LocalStoreService} from '../shared-module/services/local-store.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  langId: any;

  constructor(private _http: HttpClient, private store: LocalStoreService) {
    this.langId = '0';
  }

  public login(companyid: any, userid: any, password: any, uniqueId: any) {

    const headers = { 'content-type': 'application/json' };
    const data = {
      request: {
        companyid: companyid,
        deviceid: uniqueId,
        username: userid,
        password: password,
        langid: this.langId,
      }
    };

    const body = JSON.stringify(data);
    return this._http.post(environment.apiUrl + 'cdirpm/rest/cdirpmService/userlogin', body, { headers: headers });
  }

  public isLoggedInToken(): any {
    return localStorage.getItem('frontend-token');
  }


  public getSystemSetupData(): any {
    return this.store.getItem('systemSetupData')[0];
  }

  public getLiterals(): any {
    return this.store.getItem('systemSetupData')[0].literals;
  }


  getGroups(companyid: string, deviceId: string) {

    const headers = new HttpHeaders()
      .set('content-type', 'application/json');

    const data = {
      request: {
        companyid,
        deviceid: deviceId,
        token: this.isLoggedInToken()
      }
    };

    const body = JSON.stringify(data);
    return this._http.post(environment.apiUrl + 'cdirpm/rest/cdirpmService/getgroups', body, { headers: headers });
  }

}
