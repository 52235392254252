<div class="dashboard-wrap h-100">
        
  <app-sidebar #sidebar></app-sidebar>

  <div class="dashboard-navigation-bg"></div>

  <div class="dashboard-content-wrap">

      <header class="page-header">
          <div class="row">
              
              <div class="col-md-8">
                  <h1>{{literals.LblWelcome}}</h1>
                  <h3>{{literals.LblYouCanReview}}</h3>
              </div>
              <div class="col-md-4 d-flex justify-content-end logged-in-user">

                  <div class="mobile-logo">
                      <a href="javascript:void(0)" class="open-mobile-menu mr-2">
                          <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0 12H18V9.98438H0V12ZM0 5.01562V6.98438H13V5.01562H0ZM0 0V2.01562H18V0H0Z" fill="#555555"/>
                          </svg>
                      </a>                                
                      <img width="110" src="../../assets/images/Logo.svg" alt="RPM">
                  </div>
                  
                 <app-header></app-header>

              </div>
          </div>
      </header>

      <div *ngIf="dashboardData" class="dashboard-stats">
          <div class="row">
              <div class="col-md-4">
                  <div class="activity-box green">
                      <div class="graph-data">
                          <div class="graph-vector">
                              <circle-progress
                                      [percent]="activeUsersPercentage"
                                      [outerStrokeColor]="'#23DE9A'"></circle-progress>
                          </div>
                          <div class="percentage">
                              <strong>{{ activeUsersPercentage | number:'1.0-2' }}%</strong>
                              
                              <p>{{literals.LblActiveUsers}}</p>
                          </div>
                      </div>
                      <div class="total-counts">
                          <div class="count-icon">
                              <span class="material-icons">people</span>
                          </div>
                          <div class="count-details">
                              <strong>{{dashboardData.totaluser}}</strong>
                              <p>{{literals.LblUsers}}</p>
                          </div>
                      </div>
                  </div>
              </div>
<!--              <div class="col-md-3">-->
<!--                  <div class="activity-box blue">-->
<!--                      <div class="graph-data">-->
<!--                          <div class="graph-vector">-->
<!--                              <circle-progress-->
<!--                                      [percent]="80"-->
<!--                                      [outerStrokeColor]="'#0F62FE'"></circle-progress>-->
<!--                          </div>-->
<!--                          <div class="percentage">-->
<!--                              <strong>80%</strong>-->
<!--                              <p>Secure Tables</p>-->
<!--                          </div>-->
<!--                      </div>-->
<!--                      <div class="total-counts">-->
<!--                          <div class="count-icon">-->
<!--                              <span class="material-icons">table_chart</span>-->
<!--                          </div>-->
<!--                          <div class="count-details">-->
<!--                              <strong>84</strong>-->
<!--                              <p>Tables</p>-->
<!--                          </div>-->
<!--                      </div>-->
<!--                  </div>-->
<!--              </div>-->
              <div class="col-md-4">
                  <div class="activity-box purple">
                      <div class="graph-data">
                          <div class="graph-vector">
                              <circle-progress
                                      [percent]="usedGroupsPercentage"
                                      [outerStrokeColor]="'#B496D6'"></circle-progress>
                          </div>
                          <div class="percentage">
                              <strong>{{usedGroupsPercentage  | number:'1.0-2'}} %</strong>
                              <p>{{literals.LblUnusedGroup}}</p>
                          </div>
                      </div>
                      <div class="total-counts">
                          <div class="count-icon">
                              <span class="material-icons">group</span>
                          </div>
                          <div class="count-details">
                              <strong>{{dashboardData.totalgroup}}</strong>
                              <p>{{literals.LblGroups}}</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-4">
                  <div class="activity-box red">
                      <div class="graph-data">
                          <div class="graph-vector">
                              <circle-progress
                                      [percent]="rolesPercentage"
                                      [outerStrokeColor]="'#FF808B'"></circle-progress>
                          </div>
                          <div class="percentage">
                              <strong>{{rolesPercentage  | number:'1.0-2'}} %</strong>
                              <p>{{literals.LblUnassignedRoles}}</p>
                          </div>
                      </div>
                      <div class="total-counts">
                          <div class="count-icon">
                              <span class="material-icons">offline_pin</span>
                          </div>
                          <div class="count-details">
                              <strong>{{dashboardData.totalrolls}}</strong>
                              <p>{{literals.LblRoles}}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="group-search-wrap my-4" *ngIf="!isListEmpty">
            <div class="row">
                <div class="col-md-6">
                    <div class="input-group">
                        <input #ele type="text" (keyup)="doFilter(ele.value)" class="form-control right" placeholder="{{literals.LblSearchUsers}}">
                        <div class="input-group-append">
                            <span class="input-group-text"><span class="material-icons">search</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      <div class="mat-elevation-z8" *ngIf="!isListEmpty">
          <table class="w-100" mat-table [dataSource]="dataSource" matSort matSortStart="desc">
              <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                  <td mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
              </ng-container>
              <ng-container matColumnDef="User_Id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{literals.LblUserId}}</th>
                  <td mat-cell *matCellDef="let element; let i = index"> {{element.User_Id}} </td>
              </ng-container>
              <ng-container matColumnDef="User_Name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{literals.LblUserName}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.User_Name}} </td>
              </ng-container>
              <ng-container matColumnDef="LoginTime">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{literals.LblLoginDateTime}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.LoginTime | date: 'medium'}} </td>
              </ng-container>
              <ng-container matColumnDef="LogoutTime">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{literals.LblLogoutDateTime}} </th>
                  <td mat-cell *matCellDef="let element">{{element.LogoutTime | date: 'medium'}}</td>
              </ng-container>
              <ng-container matColumnDef="Duration">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{literals.LblDuration}} </th>
                  <td mat-cell *matCellDef="let element"> {{element.Duration}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>

      <div *ngIf="isListEmpty" class="no-data-found-box text-muted my-5 py-5 d-flex align-items-center justify-content-center">
            {{literals.LblNoUsersAvailable}}
      </div>
  </div>

</div>
